import React from "react";
import { Helmet } from "react-helmet";

import ExpertiseHeader from "./components/ExpertiseHeader/ExpertiseHeader";
import ExpertiseContent from "./components/ExpertiseContent/ExpertiseContent";

import "./ExpertisePage.scss";

const ExpertisePage = () => {
  return (
    <div className="expertisePage">
      <Helmet>
        <meta
          name="title"
          content="Expertise In Clinical Trial Phases | STATECS"
        />
        <meta
          name="description"
          content="STATECS managers have an expertise in a wide range of therapeutic areas and will support you across all the phases and throughout all the processes."
        />
        <title>Expertise In Clinical Trial Phases | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ExpertiseHeader />
      <ExpertiseContent />
    </div>
  );
};

export default ExpertisePage;
