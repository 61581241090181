import React from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/store";

import AppLayout from "./components/AppLayout/AppLayout";
import HomePage from "./pages/HomePage/HomePage";
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import ExpertisePage from "./pages/ExpertisePage/ExpertisePage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import BlogPage from "./pages/BlogPage/BlogPage";
import BlogSinglePage from "./pages/BlogSinglePage/BlogSinglePage";
import JoinUsPage from "./pages/JoinUsPage/JoinUsPage";
import TraningsPage from "./pages/TrainingsPage/TrainingsPage";
import SASTrainingPage from "./pages/SASTrainingPage/SASTrainingPage";
import DataManagementTrainingPage from "./pages/DataManagementTrainingPage/DataManagementTrainingPage";
import ServicesMedicalWriting from "./pages/ServicesMedicalWriting/ServicesMedicalWriting";
import ClinicalDataManagement from "./pages/ClinicalDataManagement/ClinicalDataManagement";
import StatisticalProgramming from "./pages/StatisticalProgramming/StatisticalProgramming";
import RegulatoryAffairs from "./pages/RegulatoryAffairs/RegulatoryAffairs";
import ApplySASTrainingPage from "./pages/ApplySASTrainingPage/ApplySASTrainingPage";
import ApplyDMTrainingPage from "./pages/ApplyDMTrainingPage/ApplyDMTrainingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermAndConditions from "./pages/TermAndConditions/TermAndConditions";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";

import "./App.css";
import "antd/dist/antd.min.css";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <AppLayout
            children={
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route
                  path="services/medical-writing"
                  element={<ServicesMedicalWriting />}
                />
                <Route
                  path="services/clinical-data-management"
                  element={<ClinicalDataManagement />}
                />
                <Route
                  path="services/statistical-programming"
                  element={<StatisticalProgramming />}
                />
                <Route
                  path="services/regulatory-affairs"
                  element={<RegulatoryAffairs />}
                />
                <Route path="/expertise" element={<ExpertisePage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:id" element={<BlogSinglePage />} />
                <Route path="/careers" element={<JoinUsPage />} />
                <Route path="/trainings" element={<TraningsPage />} />
                <Route
                  path="/trainings/sas-trainings"
                  element={<SASTrainingPage />}
                />
                <Route
                  path="/trainings/apply-sas-trainings"
                  element={<ApplySASTrainingPage />}
                />
                <Route
                  path="/trainings/apply-data-management-trainings"
                  element={<ApplyDMTrainingPage />}
                />
                <Route
                  path="/trainings/data-management-trainings"
                  element={<DataManagementTrainingPage />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/term-and-conditions"
                  element={<TermAndConditions />}
                />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            }
          />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
