import React from "react";

import { Typography } from "antd";

import "./TermAndConditions.scss";

const { Text } = Typography;

const TermAndConditions = () => {
  return (
    <div className="TermAndConditionsPage">
      <div className="TermAndConditionsContainer">
        <Text className="TermAndConditionsSectionTitle">
          1. SCOPE OF TERMS &#38; CONDITIONS
        </Text>
        <Text className="TermAndConditionsSubtitle">
          The terms and conditions outlined in this document must be followed in
          order to use this website (this "Website"). By using this website,
          you, the user, agree that these Terms of Service will apply to your
          use of it. Additional terms, policies, or rules that may apply to
          certain services on this Website and be updated from time to time may
          also apply to you when using those services.
        </Text>

        <Text className="TermAndConditionsSectionTitle">
          2. WEBSITE ACCESS USAGE RESTRICTIONS
        </Text>
        <Text className="TermAndConditionsSubtitle">
          We retain the right to discontinue or change the service we offer on
          our site without prior notice, and access to our site is only
          permitted temporarily (see below). If our site is down at any time or
          for any length of time for any reason, we will not be held
          responsible. We reserve the right to occasionally limit access to our
          website to users who have registered with us.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          If you select or are given a user identification code, password, or
          any other piece of information as part of our security measures, you
          are required to treat such information as confidential and are not
          permitted to share it with anyone.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          If we believe you have violated any of the conditions of this
          agreement, we reserve the right to disable any user identification
          number or password, whether they were assigned by us or chosen by you,
          at any time.
        </Text>

        <Text className="TermAndConditionsSectionTitle">
          3. COPYRIGHT, TRADEMARK AND INTELLECTUAL PROPERTY OWNERSHIP
        </Text>
        <Text className="TermAndConditionsSubtitle">
          All intellectual property rights in our website and the content
          published on it are owned by us or are under license. Those works are
          under safeguard of global copyright laws and agreements. These rights
          are all reserved.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          You should not make any changes to the paper or digital copies of any
          items you have printed or downloaded, and you may not use any
          pictures, sounds, videos, or other visuals without the associated text
        </Text>
        <Text className="TermAndConditionsSubtitle">
          All content on our site that has been created by us (or by any known
          contributors) is acknowledged as our own work.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          You are not allowed to utilize any materials on our site for
          profit-making endeavors without obtaining a proper license from us or
          our licensors. Your permission to use our site immediately ends if you
          print off, copy, or download any content in violation of these terms
          of use, and you are required to return or delete any copies you have
          made of the contents, at our choice.
        </Text>

        <Text className="TermAndConditionsSectionTitle">
          4. THE RIGHT TO MODIFY TERMS &#38; CONDITIONS
        </Text>
        <Text className="TermAndConditionsSubtitle">
          STATECS reserves the right to modify these terms and conditions in
          whole or in part at any time and from time to time. You consent to
          periodically reviewing these Terms and Conditions. Any modifications
          to these Terms and Conditions will take effect as soon as they are
          posted to the Online Services.{" "}
        </Text>

        <Text className="TermAndConditionsSectionTitle">
          5. INFORMATION YOU PROVIDE US
        </Text>
        <Text className="TermAndConditionsSubtitle">
          STATECS does not want or require you to provide any confidential or
          proprietary information over the website. Any submission you make to
          STATECS, such as questions, comments, recommendations, etc. will be
          considered non-confidential and will become STATECS property.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          Whenever you provide personal information to us, including when
          submitting a job or training application, you agree to the following:
        </Text>

        <ul>
          <li>
            Provide accurate, current, and complete information about yourself
            to us and to refrain from providing any information that purports to
            represent another person;
          </li>
          <li>
            Maintain or promptly update such information to keep it accurate,
            current, and complete.
          </li>
        </ul>

        <Text className="TermAndConditionsSubtitle">
          Furthermore, by your submission you grant STATECS an unrestricted,
          irrevocable license to use, reproduce, display, perform, transmit such
          information.
        </Text>

        <Text className="TermAndConditionsSectionTitle">
          6. DIGITAL COMMUNICATION FROM STATECS
        </Text>
        <Text className="TermAndConditionsSubtitle">
          By providing personal information to STATECS, you consent to receive
          informational and/or marketing communications via email, phone call,
          and/or text message.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          Please get in touch with us using the details in the "Contact
          Information" section below if you have any queries regarding receiving
          communications from us. You can stop receiving marketing emails from
          us by clicking the "Unsubscribe" link at the bottom of our
          communications to you.
        </Text>

        <Text className="TermAndConditionsSectionTitle">
          7. DISCLAIMER: NO WARRANTY OR LIABILITY
        </Text>
        <Text className="TermAndConditionsSubtitle">
          This website's factual data comes from sources that are thought to be
          credible and dependable. In addition, STATECS disclaims all liability
          for any mistakes or omissions in the information, whether brought on
          by STATECS’ or the material's source, and makes no representations or
          warranties, either express or implied, as to the accuracy, timeliness,
          or completeness of the information. Without limiting the generality of
          the foregoing, ANY AND ALL WARRANTIES OF ACCURACY, NON-INFRINGEMENT,
          MERCHANTABILITY FOR A PARTICULAR PURPOSE ARE SPECIFICALLY DISCLAIMED.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          You acknowledge and accept that your use of this website may result in
          losses of data or harm to your computer system, and that STATECS holds
          no responsibility for any such consequences. You are not permitted to
          make any warranties based on any advice or information, whether oral
          or written, that you acquire from STATECS or through this website.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          The information on this website shouldn't be used in place of
          consulting a licensed attorney or regulatory expert for legal or
          regulatory advice. The thoughts and comments posted on this website
          are those of the individual authors and may not represent those of
          STATECS or any of its agencies or employees.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          Nothing provided on this website is intended to establish an
          attorney-client relationship or to provide legal or regulatory advice.
          No attorney-client relationship of any sort is established as a result
          of the transmission of the material on this website, your use of the
          information, or communications with STATECS, its employees, or agents.
          No information sent to STATECS via this website will be covered by the
          work product doctrine or the attorney-client privilege.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          All content on our site that has been created by us (or by any known
          contributors) is acknowledged as our own work. You are not permitted
          to utilize any portion of the materials on our site for profit-making
          endeavors without first obtaining a license from us or our licensors.
        </Text>
        <Text className="TermAndConditionsSubtitle">
          Your permission to use our site immediately ends if you print off,
          copy, or download any content in violation of these terms of use, and
          you are required to return or delete any copies you have made of the
          contents, at our choice.
        </Text>
      </div>
    </div>
  );
};

export default TermAndConditions;
