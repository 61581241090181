import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { PostNewsletterSubscriptionAction } from "../../redux/action";
import { newsletterSubscriptionSelector } from "../../redux/selector/selector";

import { Col, Form, Input, Button, message, Typography } from "antd";
import SuccessModal from "../../shared/components/SuccessModal/SuccessModal";

import suffix from "../../assets/images/input-arrow-arrow.svg";
import "./NewsletterSubscription.scss";

const { Text } = Typography;
const FormItem = Form.Item;

const NewsletterSubscription = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();
  const newsletterSubscription = useSelector(newsletterSubscriptionSelector);

  const onFinish = async (values) => {
    dispatch(
      PostNewsletterSubscriptionAction(values, (status) => {
        if (status.type === "success") {
          setIsModalVisible(true);
        } else if (status.type === "faild") {
          message.error(newsletterSubscription?.error?.data?.email[0]);
        }
      })
    );
  };

  return (
    <div className="newsletterSubscription">
      <div className="newsletterSubscriptionContainer">
        <div className="textContent">
          <Text className="title">Newsletter Subscription</Text>
          <Text className="subtitle">To contact us send Your email</Text>
        </div>
        <div className="input">
          <Form
            name="contactUsForm"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            className="contactUsForm"
          >
            <Col className="form-items">
              <FormItem
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  placeholder="your email"
                  suffix={
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="suffixContainer"
                    >
                      <img src={suffix} className="suffix" alt="suffix" />
                    </Button>
                  }
                />
              </FormItem>
            </Col>
          </Form>
        </div>
        <SuccessModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          title="Thank you for applying"
          subtitle="You will be notified via
          Email about further steps"
        />
      </div>
    </div>
  );
};

export default NewsletterSubscription;
