import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";
import OurServicesCard from "./OurServicesCard/OurServicesCard";
import OurServicesCarousel from "./OurServicesCarousel/OurServicesCarousel";

import { OUR_SERVICES_CONSTANTS } from "../../../../shared/constants/ourServices.constants";
import "./OurServices.scss";

const { Text } = Typography;

const OurServices = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="ourServices">
      <div className="ourServicesContainer">
        <div className="ourServicesInfo">
          <h2 className="ourServicesInfoTitle">OUR SERVICES</h2>
          <Text className="ourServicesInfoSubtitle">
            At STATECS, preciseness and a client-centric approach are at the
            heart of our values.
          </Text>
          <Text className="ourServicesInfoSubtitle">
            We commit to providing high-quality services to leading
            pharmaceutical companies and other Contract Research Organizations
            (CROs) in the world.
          </Text>
          <Link to="/services" className="learnButton" onClick={scrollToTop}>
            Learn More
          </Link>
        </div>
        <div className="ourServicesCards">
          <div className="ourServicesCardsContainer">
            {OUR_SERVICES_CONSTANTS.slice(0, 2)?.map(
              ({ title, subtitle, icon, path }, index) => {
                return (
                  <OurServicesCard
                    title={title}
                    subtitle={subtitle}
                    icon={icon}
                    key={index}
                    href={path}
                  />
                );
              }
            )}
          </div>
          <div className="ourServicesCardsContainer">
            {OUR_SERVICES_CONSTANTS.slice(2, 4)?.map(
              ({ title, subtitle, icon, path }, index) => {
                return (
                  <OurServicesCard
                    title={title}
                    subtitle={subtitle}
                    icon={icon}
                    key={index}
                    href={path}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
      <OurServicesCarousel />
    </div>
  );
};

export default OurServices;
