import React from "react";
import { Typography } from "antd";

import WhyStatecsCard from "./WhyStatecsCard/WhyStatecsCard";
import WhyStatecsCarousel from "./WhyStatecsCarousel/WhyStatecsCarousel";

import { WHY_STATECS_CONSTANTS } from "../../../../shared/constants/whyStatecs.constnats";
import "./WhyStatecs.scss";

const { Text } = Typography;

const WhyStatecs = () => {
  return (
    <div className="whyStatecs">
      <div className="whyStatecsContainer">
        <h2 className="title">WHY STATECS?</h2>
        <Text className="subtitle">
          From the beginning of our journey till this day, we have always
          prioritized 4 fundamental and highly interconnected values:
          <span className="bold"> Trust, Quality, Care and Loyalty.</span>
        </Text>
        <Text className="subtitle">
          At STATECS, we believe trust is forged through quality work. When
          there’s trust and quality, there comes the care - care towards bigger
          goals and results. All these values together build loyalty, which
          makes us even stronger and incomparable․
        </Text>
        <div className="whyStatecsCards">
          {WHY_STATECS_CONSTANTS?.map(({ title, subtitle, path }, index) => {
            return (
              <WhyStatecsCard
                title={title}
                subtitle={subtitle}
                path={path}
                key={index}
              />
            );
          })}
        </div>
        <WhyStatecsCarousel />
      </div>
    </div>
  );
};

export default WhyStatecs;
