export const ABOUT_US_VALUES_CONSTANTS = [
  {
    title: "TRUST",
    subtitle:
      "Trust is one of the most crucial aspects of any successful cooperation. Years of hard work and dedication helped us build trust and have long-term partnerships with our clients.",
  },
  {
    title: "QUALITY",
    subtitle:
      "Our long experience in the field showed us that quality work always pays back. Whether it pays back through achievements or clients’ gratitude, it always helps us move further.",
  },
  {
    title: "CARE",
    subtitle:
      "Care is the cornerstone of our organization. Our caring and transparent team helps us build a tight relationships within our organization and with our clients.",
  },
  {
    title: "LOYALTY",
    subtitle:
      "Loyalty is in the roots of our company whether it has to do with our team members, partners or company values. It is through our loyalty to core values that we meet success.",
  },
];
