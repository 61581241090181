import React from "react";

import StatisticsSectionCard from "./StatisticsSectionCard/StatisticsSectionCard";

import { STATISTICS_CONSTANTS } from "../../../../shared/constants/statistics.constants";
import "./StatisticsSection.scss";

const StatisticsSection = () => {
  return (
    <div className="statisticsSection">
      <div className="statisticsSectionContainer">
        {STATISTICS_CONSTANTS?.map(({ inNumbers, title, subtitle }, index) => {
          return (
            <StatisticsSectionCard
              key={index}
              inNumbers={inNumbers}
              title={title}
              subtitle={subtitle}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StatisticsSection;
