import React from "react";
import { Helmet } from "react-helmet";

import BlogPageContent from "./BlogPageContent/BlogPageContent";

import "./BlogPage.scss";

const BlogPage = () => {
  return (
    <div className="blogPage">
      <Helmet>
        <meta name="title" content="Blog | STATECS" />
        <meta
          name="description"
          content="Welcome to the world of STATECS CRO: here you can find information, insights and the best practices related to the healthcare industry."
        />
        <title>Blog | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="blogPageHeader" />
      <BlogPageContent />
    </div>
  );
};

export default BlogPage;
