import { postNewsletterSubscriptionActionEnum } from "../actionType";
import { PostNewsletterSubscription } from "../service/service";

export const PostNewsletterSubscriptionAction =
  (data, callback) => async (dispatch) => {
    dispatch({
      type: postNewsletterSubscriptionActionEnum.IS_LOADING_NEWSLETTER_SUBSCRIPTION,
    });
    try {
      const response = await PostNewsletterSubscription(data);

      dispatch({
        type: postNewsletterSubscriptionActionEnum.POST_NEWSLETTER_SUBSCRIPTION_SUCCESS,
        payloda: response,
      });
      callback({ type: "success" });
    } catch (error) {
      dispatch({
        type: postNewsletterSubscriptionActionEnum.POST_NEWSLETTER_SUBSCRIPTION_ERROR,
        payload: error,
      });
      callback({ type: "faild" });
    }
  };
