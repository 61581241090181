import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getBlogByIdAction } from "../../../redux/action";
import { getBlogByIdSelector } from "../../../redux/selector/selector";

import { Typography } from "antd";

import facebookIcon from "../../../assets/images/facebook.png";
import linkedinIcon from "../../../assets/images/linkedin.png";
import "./BlogSinglePageContent.scss";

const { Text } = Typography;

const BlogSinglePageContent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data } = useSelector(getBlogByIdSelector);

  useEffect(() => {
    dispatch(getBlogByIdAction(id));
  }, [dispatch, id]);

  const content = data?.text?.split("\r\n");

  return (
    <div className="blogSinglePageContent">
      <Text className="title">{data?.title ? data?.title : "—"}</Text>
      <div className="dateContainer">
        <Text className="date">{data?.date ? data?.date : "—"}</Text>
        <div className="social">
          <a
            target="_blanck"
            href="https://www.linkedin.com/company/statecs-llc/"
          >
            <img
              src={linkedinIcon}
              alt="statecs-linkedin"
              className="statecsLinkedinSocialIcon"
            />
          </a>

          <a target="_blanck" href="https://www.facebook.com/statecsllc">
            <img src={facebookIcon} alt="statecs-facebook" />
          </a>
        </div>
      </div>
      <div className="blogContent">
        {content?.map((item, index) => {
          return <Text key={index}>{item}</Text>;
        })}
      </div>
      {data?.image_url ? (
        <img
          src={data?.image_url}
          alt="statecs-blog"
          className="statecsBlogImageBig"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default BlogSinglePageContent;
