import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import "./StatecsAdvantageSubcard.scss";

const { Text } = Typography;

const StatecsAdvantageSubcard = ({ icon, content, path }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Link to={path} className="statecsAdvantageSubcard" onClick={scrollToTop}>
      <img
        src={icon}
        alt="statecs-advantage-icon"
        className="statecsAdvantageIcon"
      />
      <Text className="statecsAdvantageSubcardContent">{content}</Text>
    </Link>
  );
};

export default StatecsAdvantageSubcard;
