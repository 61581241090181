import { getBlogPageActionEnum } from "../actionType";
import { GetBlogPage } from "../service/service";

export const getBlogPageAction = () => async (dispatch) => {
  dispatch({ type: getBlogPageActionEnum.IS_LOADING_BLOG_PAGE });
  try {
    const data = await GetBlogPage();

    dispatch({
      type: getBlogPageActionEnum.GET_BLOG_PAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: getBlogPageActionEnum.GET_BLOG_PAGE_ERROR,
      error: error,
    });
  }
};
