import React from "react";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import TrainingsSection from "./components/TrainingsSection/TrainingsSection";
import ProgramOverview from "./components/ProgramOverview/ProgramOverview";
import AfterTrainings from "./components/AfterTrainings/AfterTrainings";

import "./TrainingsPage.scss";

const { Text } = Typography;

const TrainingsPage = () => {
  return (
    <div className="trainingsPage">
      <Helmet>
        <meta name="title" content="Trainings | STATECS" />
        <meta
          name="description"
          content="STATECS training programs are designed to provide in-depth practical knowledge to the participants and lasts 1-3 months depending on the program."
        />
        <title>Trainings | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="trainingsPageHeader">
        <div className="trainingsPageHeaderBackground" />
        <div className="trainingsPageHeaderContainer">
          <h1 className="trainingsPageHeaderTitle">STATECS Trainings</h1>
          <Text className="trainingsPageHeaderSubtitle">
            AT STATECS TRAINING CENTER WE EMPOWER ANY BEGINNER OR AN ADVANCED
            PROGRAMMER BY PROVIDING THEM EXTENSIVE KNOWLEDGE, EXPERIENCE AND
            SKILLS FOR FUTURE CAREER SUCCESSES.
          </Text>
          <Link
            Link
            to="trainingsSection"
            smooth="true"
            offset={-90}
            duration={800}
            className="trainingsPageHeaderApplyButton"
          >
            Apply
          </Link>
        </div>
      </div>
      <TrainingsSection />
      <ProgramOverview />
      <AfterTrainings />
    </div>
  );
};

export default TrainingsPage;
