import React from "react";

import { Typography } from "antd";
import ServiceCard from "../../../../shared/components/ServiceCard/ServiceCard";

import { STATISTICAL_PROGRAMMING_SERVICES_CONSTANTS } from "../../../../shared/constants/servicesSubPage.constants";
import "./StatisticalProgrammingContent.scss";

const { Text } = Typography;

const StatisticalProgrammingContent = () => {
  return (
    <div className="statisticalProgrammingContent">
      <Text className="statisticalProgrammingTextContent">
        Statistical programming is an integral part of the biotech and
        pharmaceutical industry as it helps to turn large amounts of complex
        medical data into definable information. Statistical programming also
        enables detailed reporting through the creation of databases and the
        creation of Tables, Listings, and Figures (TLF).
      </Text>
      <Text className="statisticalProgrammingTextContent">
        Whether you are a small or big pharmaceutical organization, enterprise,
        or mid-size biotech company, statistical programming is one of the most
        fundamental aspects you will ever need. STATECS programmers can provide
        you with several solutions that will help you collect, analyze, and
        report various types of data. Having an appropriate solution in your
        hand, you will be able to significantly simplify and accelerate the
        overall clinical trial procedures.
      </Text>
      <h2 className="statisticalProgrammingContentTitle">
        STATISTICAL PROGRAMMING SERVICES:
      </h2>
      <Text className="statisticalProgrammingTextContent">
        Our qualified statistical programmers have in-depth knowledge of all
        phases and requirements of the drug development procedure, so they know
        how to use their expertise for clients’ needs while creating
        long-lasting partnerships.
      </Text>
      <Text className="statisticalProgrammingContentSubtitle">
        Our statistical programming services include:
      </Text>
      <div className="statisticalProgrammingCards">
        {STATISTICAL_PROGRAMMING_SERVICES_CONSTANTS?.map(
          ({ content }, index) => {
            return <ServiceCard content={content} key={index} />;
          }
        )}
      </div>
    </div>
  );
};

export default StatisticalProgrammingContent;
