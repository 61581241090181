import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getBlogRelatedPostsAction } from "../../../redux/action";
import { blogRelatedPostsSelector } from "../../../redux/selector/selector";

import { Typography } from "antd";
import Slider from "react-slick";
import BlogPageItemCard from "../BlogPageItemCard/BlogPageItemCard";

import "./BlogRelatedPostsCarousel.scss";

const { Text } = Typography;

const BlogRelatedPostsCarousel = () => {
  const dispatch = useDispatch();
  const blogRelatedPosts = useSelector(blogRelatedPostsSelector);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getBlogRelatedPostsAction(id));
  }, [dispatch, id]);

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <div className="relatedPosts">
      <Text className="relatedPostsTitle">Next article:</Text>
      <div className="relatedPostsCarouselContainer">
        <Slider {...settings}>
          {blogRelatedPosts?.data?.map(({ image_url, title, text, id }) => {
            return (
              <div className="relatedPostsCarouselItem" key={id}>
                <BlogPageItemCard
                  img={image_url}
                  title={title}
                  subtitle={text}
                  id={id}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default BlogRelatedPostsCarousel;
