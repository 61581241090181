import { applyForJobActionEnum } from "../actionType";
import { ApplyJob } from "../service/service";

export const applyForJobAction = (data, callback) => async (dispatch) => {
  dispatch({
    type: applyForJobActionEnum.IS_LOADING_APPLY_JOB,
  });
  try {
    const response = await ApplyJob(data);

    dispatch({
      type: applyForJobActionEnum.APPLY_JOB_SUCCESS,
      payloda: response,
    });
    callback({ type: "success" });
  } catch (error) {
    dispatch({
      type: applyForJobActionEnum.APPLY_JOB_ERROR,
      payload: error,
    });
    callback({ type: "faild" });
  }
};
