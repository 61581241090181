import React from "react";
import { Row, Carousel } from "antd";

import AfterTrainingsCard from "../../../../shared/components/AfterTrainingsCard/AfterTrainingsCard";

import { AFTER_TRAININGS_CONSTANTS } from "../../../../shared/constants/afterTrainings.constants";
import "./AfterTrainingCarousel.scss";

const AfterTrainingCarousel = () => {
  return (
    <div className="afterTrainingCarouselContainer">
      <Row className="carousel" id="carousel">
        <Carousel autoplay>
          {AFTER_TRAININGS_CONSTANTS?.map(({ icon, content }, index) => {
            return (
              <AfterTrainingsCard icon={icon} content={content} key={index} />
            );
          })}
        </Carousel>
      </Row>
    </div>
  );
};

export default AfterTrainingCarousel;
