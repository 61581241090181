import React from "react";

import { Typography } from "antd";
import ServiceCard from "../../../../shared/components/ServiceCard/ServiceCard";

import { REGULATORY_AFFAIRS_SERVICES_CONSTANTS } from "../../../../shared/constants/servicesSubPage.constants";
import "./RegulatoryAffairsContent.scss";

const { Text } = Typography;

const RegulatoryAffairsContent = () => {
  return (
    <div className="regulatoryAffairsContent">
      <Text className="regulatoryAffairsTextContent">
        The regulatory affairs process plays a critical role in the development,
        submission, and distribution of medical applications that require
        extensive knowledge and experience about global regulatory legislation
        changes in all the regions where a company wishes to distribute its
        products. Strategic and technical advice from professionals is a must
        for both the commercial and scientific success of a development program
        and the company.
      </Text>
      <Text className="regulatoryAffairsTextContent">
        On the other hand, regulatory submission is an extremely important and
        complex process that requires time and appropriate knowledge of
        prerequisites for successful approval. Moreover, the most challenging
        part is that if your submission gets rejected by the FDA, then the
        second submission can take up to 435 days. This means that your
        marketing activities, business development, and other aspects will be
        delayed, not to mention the variety of fees that come with the
        resubmission process.
      </Text>
      <Text className="regulatoryAffairsContentSubtitle">
        Our experts will check your submission content from all possible
        aspects:
      </Text>
      <div className="regulatoryAffairsCards">
        {REGULATORY_AFFAIRS_SERVICES_CONSTANTS?.map(({ content }, index) => {
          return <ServiceCard content={content} key={index} />;
        })}
      </div>
    </div>
  );
};

export default RegulatoryAffairsContent;
