import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import "./ServicesSectionCard.scss";

const { Text } = Typography;

const ServicesSectionCard = ({ icon, title, subtitle, url }) => {
  return (
    <Link to={url} className="servicesSectionCard">
      <img src={icon} alt="service-icon" className="icon" />
      {title ? <Text className="title">{title}</Text> : ""}
      <Text className="subtitle">{subtitle}</Text>
    </Link>
  );
};

export default ServicesSectionCard;
