import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import "./OurExpertise.scss";

const { Text } = Typography;

const OurExpertise = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="ourExpertise">
      <Text className="ourExpertiseTitle">OUR EXPERTISE</Text>
      <Text className="ourExpertiseSubtitle">
        Having more than 10 years of working experience with other CROs, we have
        worked on numerous successful projects providing them full assistance
        every step of the way. By working with us, you get a large team of
        experienced and dedicated specialists who have expertise in Clinical
        Trial Phases, ISS ISE Services, and CDISC compliance across all main
        Therapeutic Areas.
      </Text>
      <Link to="/expertise" className="seeMoreButton" onClick={scrollToTop}>
        See more
      </Link>
    </div>
  );
};

export default OurExpertise;
