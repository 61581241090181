import React from "react";

import { Typography } from "antd";
import ServiceCard from "../../../../shared/components/ServiceCard/ServiceCard";

import { MEDICAL_WRITING_CONSTANTS } from "../../../../shared/constants/servicesSubPage.constants";
import "./ServicesMedicalWritingContent.scss";

const { Text } = Typography;

const ServicesMedicalWritingContent = () => {
  return (
    <div className="servicesMedicalWritingContent">
      <Text className="servicesMedicalWritingTextContent">
        Quality medical writing of documents is critical for the clear
        communication of goals and strategies as well as for the comprehensible
        presentation of biomedical data to appropriate target audiences be it
        the healthcare professionals, patients, sponsors, regulatory agents, or
        the general public.
      </Text>
      <Text className="servicesMedicalWritingTextContent">
        STATECS provides end-to-end professional medical writing support for all
        stages of product development, from the early development period to
        application marketing, submission, and approval. We understand the
        challenges of writing for a regulated market and know how to master all
        the demanding requirements of regulatory agencies.
      </Text>
      <h2 className="servicesMedicalWritingContentTitle">
        MEDICAL WRITING SERVICES:
      </h2>
      <Text className="servicesMedicalWritingTextContent">
        Regardless of your project scope, STAECS medical writers will deliver
        quality, accurate, cost-effective clinical and regulatory documents with
        the best scientific standards in a timely manner.
      </Text>
      <Text className="servicesMedicalWritingContentSubtitle">
        Our medical writing services include:
      </Text>
      <div className="servicesMedicalWritingCards">
        {MEDICAL_WRITING_CONSTANTS?.map(({ content }, index) => {
          return <ServiceCard content={content} key={index} />;
        })}
      </div>
    </div>
  );
};

export default ServicesMedicalWritingContent;
