import React from "react";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import ServicesWhyStatecs from "./components/ServicesWhyStatecs/ServicesWhyStatecs";
import ClinicalDataManagementContent from "./components/ClinicalDataManagementContent/ClinicalDataManagementContent";
import StatecsAdvantageSubcard from "../../shared/components/StatecsAdvantageSubcard/StatecsAdvantageSubcard";

import { ADVANTAGE_CLINICAL_DATA_MANAGEMENT_SUBCARDS_CONSTANTS } from "../../shared/constants/statecsAdvantage.constants";
import "./ClinicalDataManagement.scss";

const { Text } = Typography;

const ClinicalDataManagement = () => {
  return (
    <div className="clinicalDataManagement">
      <Helmet>
        <meta
          name="title"
          content="Clinical Data Management Services | STATECS"
        />
        <meta
          name="description"
          content="STATECS clinical data managers provide high-quality clinical data management services that meet internationally accepted quality assurance standards."
        />
        <title>Clinical Data Management Services | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="clinicalDataManagementHeader">
        <div className="clinicalDataManagementHeaderContainer">
          <h1 className="clinicalDataManagementHeaderTitle">
            CLINICAL DATA MANAGEMENT
          </h1>
          <Text className="clinicalDataManagementHeaderSubtitle">
            WE OFFER CLINICAL DATA MANAGEMENT FOR SEAMLESS CLINICAL TRIALS
            ENABLING FASTER TRIAL COMPLETIONS &#38; MAXIMUM EFFICIENCY
          </Text>
        </div>
      </div>
      <ClinicalDataManagementContent />

      <div className="whyStatecsMedicalWriting">
        <ServicesWhyStatecs />
      </div>

      <div className="servicesOurAdvantages">
        <Text className="servicesOurAdvantagesTitle">OUR ADVANTAGES:</Text>
        <ul>
          <li>
            We have a team of qualified and motivated specialists with extensive
            working experience in the field ready to use our knowledge and
            skills for the success of your projects.
          </li>
          <li>
            From analysis and reporting through the regulatory process, we are
            here to support you with detail-oriented and data-driven working
            guidance.
          </li>
        </ul>
      </div>
      <div className="servicesWhyStatecsSubcards">
        {ADVANTAGE_CLINICAL_DATA_MANAGEMENT_SUBCARDS_CONSTANTS?.map(
          ({ icon, subtitle, path }, index) => {
            return (
              <StatecsAdvantageSubcard
                icon={icon}
                content={subtitle}
                path={path}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default ClinicalDataManagement;
