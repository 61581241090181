import React from "react";

import { Typography } from "antd";
import StatecsAdvantageCard from "../../../../shared/components/StatecsAdvantageCard/StatecsAdvantageCard";

import { ADVANTAGE_STATISTICAL_PROGRAMMING_SERVICES_CONSTANTS } from "../../../../shared/constants/statecsAdvantage.constants";
import "./ServicesWhyStatecs.scss";

const { Text } = Typography;

const ServicesWhyStatecs = () => {
  return (
    <div className="servicesWhyStatecs">
      <Text className="servicesWhyStatecsTitle">WHY STATECS?</Text>
      <Text className="servicesWhyStatecsText">
        Due to our 10 years of experience in the field, STATECS programmers are
        aware of all industry requirements, standards, and advances to deliver
        you the most efficient data. Our experienced statistical programmers
        will ensure that your data is valid, understandable, and appropriate for
        the safety and efficiency of medical products.
      </Text>
      <Text className="servicesWhyStatecsText">
        We offer flexibility, affordability, and high quality in a single
        package: this means you can rest assured that our team of qualified
        specialists will handle your data according to the best standards to
        meet all your needs and requirements.
      </Text>
      <Text className="servicesWhyStatecsSubtitle">
        WHAT MAKES US DIFFERENT :
      </Text>
      <div className="servicesWhyStatecsCards">
        {ADVANTAGE_STATISTICAL_PROGRAMMING_SERVICES_CONSTANTS?.map(
          ({ icon, subtitle }, index) => {
            return (
              <StatecsAdvantageCard
                icon={icon}
                subtitle={subtitle}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default ServicesWhyStatecs;
