import React from "react";

import { Typography } from "antd";
import StatecsAdvantageCard from "../../../../shared/components/StatecsAdvantageCard/StatecsAdvantageCard";

import { SERVICES_ADVANTAGE_CONSTANTS } from "../../../../shared/constants/statecsAdvantage.constants";
import "./StatecsAdvantage.scss";

const { Text } = Typography;

const StatecsAdvantage = () => {
  return (
    <div className="statecsAdvantage">
      <div className="statecsAdvantageBackground">
        <Text className="statecsAdvantageBackgroundTitle">
          WHAT YOU GET WITH STATECS:
        </Text>
      </div>
      <Text className="statecsAdvantageTitle">WHAT YOU GET WITH STATECS:</Text>
      <div className="statecsAdvantageCards">
        {SERVICES_ADVANTAGE_CONSTANTS?.map(
          ({ title, subtitle, icon }, index) => {
            return (
              <StatecsAdvantageCard
                icon={icon}
                title={title}
                subtitle={subtitle}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default StatecsAdvantage;
