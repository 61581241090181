import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import {
  sasTrainingsSelector,
  dmTrainingsSelector,
} from "../../../redux/selector/selector";
import {
  getSASTrainingsAction,
  getDMTrainingsAction,
} from "../../../redux/action";

import { Typography } from "antd";

import "./TrainingCalendar.scss";

const { Text } = Typography;

const TrainingCalendar = () => {
  const [trainingData, setTariningData] = useState();
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [hour, setHour] = useState();

  const dispatch = useDispatch();
  const location = useLocation();
  const sasTrainings = useSelector(sasTrainingsSelector);
  const dmTrainings = useSelector(dmTrainingsSelector);

  useEffect(() => {
    if (location.pathname.includes("sas-trainings")) {
      dispatch(getSASTrainingsAction());
    } else if (location.pathname.includes("data-management-trainings")) {
      dispatch(getDMTrainingsAction());
    }
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (location.pathname.includes("sas-trainings")) {
      setTariningData(sasTrainings?.data);
    } else if (location.pathname.includes("data-management-trainings")) {
      setTariningData(dmTrainings?.data);
    }
  }, [sasTrainings, dmTrainings, location.pathname]);

  const targetTime = new Date(trainingData?.start_date).getTime();

  const timeBetween = targetTime - currentTime;
  const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24 - 4));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (hours - 4 < 0) {
      setHour(24 + hours - 4);
    } else {
      setHour(hours - 4);
    }
  }, [hours]);

  return (
    <div className="trainingInfo">
      <div className="trainingInfoDate">
        <div className="trainingInfoDateCalendar">
          <div className="trainingInfoDateCalendarMonth">
            {trainingData?.start_date
              ? moment(trainingData?.start_date).format("MMM")
              : "—"}
          </div>
          <div className="trainingInfoDateCalendarDay">
            {trainingData?.start_date
              ? moment(trainingData?.start_date).date()
              : "—"}
          </div>
        </div>
        <Text className="trainingInfoDateCalendarWeekDay">
          {trainingData?.start_date
            ? moment(trainingData?.start_date).format("dddd")
            : "—"}
        </Text>
      </div>
      <div className="trainingEventDetails">
        <Text className="trainingEventDetailsTitle">Event details:</Text>
        <Text className="trainingEventDetailsSubtitle">When</Text>
        <Text className="trainingEventDetailsSubtitleContent">
          {trainingData?.when ? trainingData?.when : "—"}
        </Text>
        <Text className="trainingEventDetailsSubtitle">Where</Text>
        <Text className="trainingEventDetailsSubtitleContent">
          {trainingData?.where ? trainingData?.where : "—"}
        </Text>
        <Text className="trainingEventDetailsSubtitle">Calendar</Text>
        <Text className="trainingEventDetailsSubtitleContent">Events</Text>
      </div>
      <div className="trainingComingSoon">
        <Text className="trainingComingSoonTitle">WE ARE COMING SOON</Text>
        <div className="trainingComingSoonTimer">
          <div className="trainingComingSoonTimerCard">
            <Text className="timerCardNumbers">{days > 0 ? days : "00"}</Text>
            <Text className="timerCardText">days</Text>
          </div>
          <div className="trainingComingSoonTimerCard">
            <Text className="timerCardNumbers">{days > 0 ? hour : "00"}</Text>
            <Text className="timerCardText">hours</Text>
          </div>
          <div className="trainingComingSoonTimerCard">
            <Text className="timerCardNumbers">
              {days > 0 ? minutes : "00"}
            </Text>
            <Text className="timerCardText">minutes</Text>
          </div>
        </div>
      </div>
      <div className="trainingAgenda">
        <Text className="trainingAgendaTitle">Agenda:</Text>
        <div className="trainingAgendaContent">
          <div className="trainingAgendaContentSection">
            <Text className="trainingAgendaSubtitle">Start date</Text>
            <Text className="trainingAgendaSubtitleContent">
              {trainingData?.start_date ? trainingData?.start_date : "—"}
            </Text>
          </div>
          <div className="trainingAgendaContentSection">
            <Text className="trainingAgendaSubtitle">End date</Text>
            <Text className="trainingAgendaSubtitleContent">
              {trainingData?.end_date ? trainingData?.end_date : "—"}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingCalendar;
