import React from "react";

import { Typography } from "antd";

import "./OurValuesCard.scss";

const { Text } = Typography;

const OurValuesCard = ({ title, subtitle }) => {
  return (
    <div className="ourValuesCard">
      <Text className="title">{title}</Text>
      <Text className="subtitle">{subtitle}</Text>
    </div>
  );
};

export default OurValuesCard;
