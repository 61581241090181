import React from "react";
import { Row, Carousel } from "antd";

import ServicesSectionCard from "../ServicesSectionCard/ServicesSectionCard";

import { SERVICES_CONSTANTS } from "../../../../../shared/constants/servicesPage.constants";
import "./ServicesSectionCarousel.scss";

const ServicesSectionCarousel = () => {
  return (
    <div className="servicesCarouselSectionContainer">
      <Row className="carousel" id="carousel">
        <Carousel autoplay speed={300}>
          {SERVICES_CONSTANTS?.map(({ title, subtitle, icon, url }, index) => {
            return (
              <ServicesSectionCard
                title={title}
                subtitle={subtitle}
                icon={icon}
                url={url}
                key={index}
              />
            );
          })}
        </Carousel>
      </Row>
    </div>
  );
};

export default ServicesSectionCarousel;
