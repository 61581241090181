import React from "react";

import { Typography } from "antd";

import "./ExpertiseHeader.scss";

const { Text } = Typography;

const ExpertiseHeader = () => {
  return (
    <div className="expertiseHeader">
      <div className="expertiseHeaderBackground" />
      <div className="expertiseHeaderContainer">
        <h1 className="expertiseHeaderTitle">EXPERTISE</h1>
        <Text className="expertiseHeaderSubtitle">
          Over the past 10 years, we have worked on a large variety of clinical
          studies, supporting our clients every step of the way: from the early
          phase of research and analysis to submitting and getting CDISC
          compliant. At STATECS, we do more than provide clinical data services.
          We prioritize a client-centric approach and put our efforts into
          understanding clients’ needs. Whether you are an individual
          entrepreneur, a small organization, or a large enterprise, we will
          define your needs and goals and deliver you the best solutions within
          a mutually agreed deadline.
        </Text>
      </div>
    </div>
  );
};

export default ExpertiseHeader;
