import ClinicalTrialPhasesContent from "../../pages/ExpertisePage/components/ExpertiseContent/Collapse/components/ClinicalTrialPhasesContent/ClinicalTrialPhasesContent";
import ISSISEServicesContent from "../../pages/ExpertisePage/components/ExpertiseContent/Collapse/components/ISSISEServicesContent/ISSISEServicesContent";
import TherapeutixExperienceContent from "../../pages/ExpertisePage/components/ExpertiseContent/Collapse/components/TherapeutixExperienceContent/TherapeutixExperienceContent";
import CDISCCompliantMappingContent from "../../pages/ExpertisePage/components/ExpertiseContent/Collapse/components/CDISCCompliantMappingContent/CDISCCompliantMappingContent";
import FunctionServiceProvidersContent from "../../pages/ExpertisePage/components/ExpertiseContent/Collapse/components/FunctionServiceProvidersContent/FunctionServiceProvidersContent";
import AnalysisAndModelingContent from "../../pages/ExpertisePage/components/ExpertiseContent/Collapse/components/AnalysisAndModelingContent/AnalysisAndModelingContent";
import BayesianAdaptiveClinicalTrialDesignesContent from "../../pages/ExpertisePage/components/ExpertiseContent/Collapse/components/BayesianAdaptiveClinicalTrialDesignesContent/BayesianAdaptiveClinicalTrialDesignesContent";
import OncologyClinicalResearchOrganizationContent from "../../pages/ExpertisePage/components/ExpertiseContent/Collapse/components/OncologyClinicalResearchOrganizationContent/OncologyClinicalResearchOrganizationContent";

import clinicalTrialPhases from "../../assets/images/clinical-trial-phases.png";
import issIseServices from "../../assets/images/iss-ise-services.png";
import therapeutixExperience from "../../assets/images/therapeutix-experience.png";
import cdiscCompliantMapping from "../../assets/images/cdisc-compliant-mapping.png";
import functionServiceProviders from "../../assets/images/function-service-providers.png";
import analysisAndModeling from "../../assets/images/analysis-and-modeling.png";
import bayesianAdaptiveClinicalTrialDesignes from "../../assets/images/bayesian-adaptive-clinical-trial-designes.png";
import oncologyClinicalResearchOrganization from "../../assets/images/oncology-clinical-research-organization.png";

export const EXPERTISE_PNELS_CONSTANTS = [
  {
    icon: clinicalTrialPhases,
    title: "CLINICAL TRIAL PHASES",
    subtitle:
      "There are 3 main phases of clinical trials during which a drug/medical device/a new treatment method is tested to see if it is effective enough for public use. ",
    content: <ClinicalTrialPhasesContent />,
  },
  {
    icon: issIseServices,
    title: "ISS ISE SERVICES",
    subtitle:
      "Well-planned and high-quality ISS (Integrated Summary of Safety) and ISE (Integrated Summary of Efficacy) are crucial for the successful regulatory submission of your Common Technical Document (CTD).",
    content: <ISSISEServicesContent />,
  },
  {
    icon: therapeutixExperience,
    title: "THERAPEUTIC EXPERIENCE",
    subtitle:
      "STATECS has experience in a wide range of therapeutic areas. Check them out below:",
    content: <TherapeutixExperienceContent />,
  },
  {
    icon: cdiscCompliantMapping,
    title: "CDISC COMPLIANT/ MAPPING",
    subtitle:
      "Clinical Data Interchange Standards Consortium (CDISC) is a global not-for-profit organization that determines data standards in the pharmaceutical industry based on the experience and knowledge of volunteers (with relevant education/skills).",
    content: <CDISCCompliantMappingContent />,
  },
  {
    icon: functionServiceProviders,
    title: "FUNCTION SERVICE PROVIDERS",
    subtitle:
      "Should you run out of qualified specialists, we are here to lend a hand! Outsourcing different business operations has long become a new norm for many companies around the world. ",
    content: <FunctionServiceProvidersContent />,
  },
  {
    icon: analysisAndModeling,
    title: "PK/PD ANALYSIS AND MODELING ",
    subtitle:
      "Clinical pharmacology is the study of drug flow through the body or its interaction with the body. In other words, it studies the way drug behaves in the body and how the body reacts to the drug after that. ",
    content: <AnalysisAndModelingContent />,
  },
  {
    icon: bayesianAdaptiveClinicalTrialDesignes,
    title: "BAYESIAN ADAPTIVE CLINICAL TRIAL DESIGNS",
    subtitle:
      "In clinical research, the Bayesian statistics method offers a framework for drawing conclusions about treatment results based on data gathered from sources other than the specific clinical study in question.",
    content: <OncologyClinicalResearchOrganizationContent />,
  },
  {
    icon: oncologyClinicalResearchOrganization,
    title: "ONCOLOGY CLINICAL RESEARCH ORGANIZATION (CRO) ",
    subtitle:
      "The role of clinical research organizations (CROs) — and particularly cancer-focused CROs — has become more crucial in recent years as the number of cancer clinical trials has accelerated globally.",
    content: <BayesianAdaptiveClinicalTrialDesignesContent />,
  },
];
