import React from "react";
import { Helmet } from "react-helmet";

import { Typography } from "antd";

import ServicesSection from "./components/ServicesSection/ServicesSection";
import ServicesInfo from "./components/ServicesInfo/ServicesInfo";
import StatecsAdvantage from "./components/StatecsAdvantage/StatecsAdvantage";

import "./ServicesPage.scss";

const { Text } = Typography;

const ServicesPage = () => {
  return (
    <div className="servicesPage">
      <Helmet>
        <meta name="title" content="Our Services | STATECS" />
        <meta
          name="description"
          content="STATECS provides data-oriented CRO services, such as: statistical programming, clinical data management, medical writing and regulatory affairs."
        />
        <title>Our Services | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="servicesPageHeader">
        <div className="holla" />
        <div className="servicesPageHeaderContainer">
          <Text className="servicesPageHeaderTitle">Services</Text>
          <Text className="servicesPageHeaderSubtitle">
            Bring your drugs to the market with globally standardized, reliable,
            and easy access to expert teams with STATECS' data-oriented{" "}
            <h1>CRO services</h1> for clinical trials.
          </Text>
        </div>
      </div>
      <ServicesSection />
      <ServicesInfo />
      <StatecsAdvantage />
    </div>
  );
};

export default ServicesPage;
