import React from "react";
import { Helmet } from "react-helmet";

import Slogan from "./components/Slogan/Slogan";
import OurMission from "./components/OurMission/OurMission";
import OurServices from "./components/OurServices/OurServices";
import StatisticsSection from "./components/StatisticsSection/StatisticsSection";
import WhyStatecs from "./components/WhyStatecs/WhyStatecs";
import StatecsExpertise from "./components/StatecsExpertise/StatecsExpertise";

import "./HomePage.scss";

const HomePage = () => {
  return (
    <div className="homePage">
      <Helmet>
        <meta
          name="title"
          content="STATECS | International CRO | Clinical Research"
        />
        <meta
          name="description"
          content="As a leading international CRO, STATECS provides advanced data analytics, clinical research, healthcare technology solutions & other related services."
        />
        <title>STATECS | International CRO | Clinical Research</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Slogan />
      <OurMission />
      <OurServices />
      <StatisticsSection />
      <WhyStatecs />
      <StatecsExpertise />
    </div>
  );
};
export default HomePage;
