import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogAction, getBlogPageAction } from "../../../redux/action";
import {
  getBlogSelector,
  getBlogPageSelector,
} from "../../../redux/selector/selector";

import { Pagination } from "antd";
import BlogPageItemCard from "./BlogPageItemCard/BlogPageItemCard";

import "./BlogPageContent.scss";

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}

const BlogPageContent = () => {
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(8);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const dispatch = useDispatch();
  const blogList = useSelector(getBlogSelector);
  const { count } = useSelector(getBlogPageSelector);

  useEffect(() => {
    dispatch(getBlogAction(page, itemPerPage));
    dispatch(getBlogPageAction());
  }, [dispatch, page, itemPerPage]);

  const pageChangeHandler = (e) => {
    setPage(e);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth <= 1024) {
      setItemPerPage(4);
    } else {
      setItemPerPage(8);
    }
  }, [windowSize]);

  return (
    <div className="blogPageContent">
      <div className="blogPageContentCards">
        {blogList?.data?.slice(0, 4)?.map(({ image_url, title, text, id }) => {
          return (
            <BlogPageItemCard
              img={image_url}
              title={title}
              subtitle={text}
              key={id}
              id={id}
            />
          );
        })}
      </div>
      <div className="blogPageContentCards">
        {blogList?.data?.slice(4, 8)?.map(({ image_url, title, text, id }) => {
          return (
            <BlogPageItemCard
              img={image_url}
              title={title}
              subtitle={text}
              key={id}
              id={id}
            />
          );
        })}
      </div>
      <Pagination
        defaultCurrent={page}
        pageSize={itemPerPage}
        total={count ? count : 20}
        onChange={pageChangeHandler}
      />
    </div>
  );
};

export default BlogPageContent;
