import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { contactUsAction } from "../../../redux/action";

import { Col, Form, Input, Button } from "antd";
import SuccessModal from "../../../shared/components/SuccessModal/SuccessModal";

import "./ContactUsForm.scss";

const { TextArea } = Input;
const FormItem = Form.Item;

const ContactUsForm = () => {
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [form] = Form.useForm();

  const dipatch = useDispatch();
  const onFinish = async (values) => {
    dipatch(
      contactUsAction(values, (status) => {
        if (status.type === "success") {
          setIsSuccessModalVisible(true);
        }
      })
    );

    form.resetFields();
  };

  return (
    <div className="form">
      <Form
        form={form}
        name="contactUsForm"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        className="contactUsForm"
      >
        <Col className="form-items">
          <FormItem
            name="first_name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input placeholder="Name" />
          </FormItem>

          <FormItem
            name="last_name"
            rules={[{ required: true, message: "Please input your surname!" }]}
          >
            <Input placeholder="Surname" />
          </FormItem>

          <FormItem
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
                type: "email",
              },
            ]}
          >
            <Input placeholder="Email" />
          </FormItem>

          <FormItem
            name="company_name"
            rules={[
              { required: true, message: "Please input your company name!" },
            ]}
          >
            <Input placeholder="Company" />
          </FormItem>

          <FormItem
            name="message"
            rules={[{ required: true, message: "Please input your message!" }]}
            className="formItemTextArea"
          >
            <TextArea placeholder="Message" />
          </FormItem>
        </Col>

        <Col className="submitButtonContainer">
          <Button type="primary" htmlType="submit" className="submitButton">
            Send
          </Button>
        </Col>
      </Form>
      <SuccessModal
        isModalVisible={isSuccessModalVisible}
        setIsModalVisible={setIsSuccessModalVisible}
        title="Thank you"
        subtitle="Your message has been successfully sent"
      />
    </div>
  );
};

export default ContactUsForm;
