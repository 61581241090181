import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import "./OurServicesCard.scss";

const { Text } = Typography;

const OurServicesCard = ({ icon, title, subtitle, href }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <Link to={href} className="ourServicesCard" onClick={scrollToTop}>
      <img src={icon} alt="service-icon" className="icon" />
      <Text className="title">{title}</Text>
      <Text className="subtitle">{subtitle}</Text>
    </Link>
  );
};

export default OurServicesCard;
