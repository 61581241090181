import React from "react";

import { Typography } from "antd";
import TrainingsCard from "./TrainingsCard/TrainingsCard";

import { TRAININGS_CONSTANTS } from "../../../../shared/constants/trainings.constants";
import "./TrainingsSection.scss";

const { Text } = Typography;

const TrainingsSection = () => {
  return (
    <div className="trainingsSection" id="trainingsSection">
      <Text className="trainingsSectionTitle">
        For all the motivated and aspirational programmers out there, STATECS
        organizes regular training programs, where participants get to be
        trained by industry experts in such rapidly developing fields as
        Statistical Analysis System (SAS) and Data Management (DM).{" "}
      </Text>
      <div className="trainingsSectionCards">
        {TRAININGS_CONSTANTS?.map(({ img, title, subtitle, path }, index) => {
          return (
            <TrainingsCard
              img={img}
              title={title}
              subtitle={subtitle}
              key={index}
              path={path}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TrainingsSection;
