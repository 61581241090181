import React from "react";

import { Typography } from "antd";

import "./AboutUsPageHeader.scss";

const { Text } = Typography;

const AboutUsPageHeader = () => {
  return (
    <div className="aboutUsPageHeader">
      <div className="aboutUsHeaderContent" />
      <div className="aboutUsPageHeaderContainer">
        <Text className="headerTitle">WHO WE ARE</Text>
        <Text className="headerSubtitle">
          We are a leading international Contract Research Organization (CRO),
          providing a full package of services from medical writing, clinical
          data analytics, research, and management to regulatory affairs. We
          believe that by bringing together data science and modern technology,
          we will be able to provide innovative solutions and shift the
          healthcare industry for the better.
        </Text>
      </div>
    </div>
  );
};

export default AboutUsPageHeader;
