import { getSASTrainingsActionEnum } from "../actionType";

export const sasTrainingsReducer = function (
  state = { data: [], error: null, loading: false },
  action
) {
  switch (action.type) {
    case getSASTrainingsActionEnum.IS_LOADING_SAS_TRAININGS:
      return { ...state, loading: true };
    case getSASTrainingsActionEnum.GET_SAS_TRAININGS_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case getSASTrainingsActionEnum.GET_SAS_TRAININGS_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};
