import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import "./WhyStatecsCard.scss";

const { Text } = Typography;

const WhyStatecsCard = ({ title, subtitle, path }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <Link to={path} className="whyStatecsCard" onClick={scrollToTop}>
      <Text className="cardTitle">{title}</Text>
      <Text className="cardSubtitle">{subtitle}</Text>
    </Link>
  );
};

export default WhyStatecsCard;
