import React from "react";
import { Row, Carousel } from "antd";

import WhyStatecsCard from "../WhyStatecsCard/WhyStatecsCard";

import { WHY_STATECS_CONSTANTS } from "../../../../../shared/constants/whyStatecs.constnats";
import "./WhyStatecsCarousel.scss";

const WhyStatecsCarousel = () => {
  return (
    <div className="CarouselSectionContainer">
      <Row className="carousel" id="carousel">
        <Carousel autoplay>
          {WHY_STATECS_CONSTANTS?.map(({ title, subtitle, path }, index) => {
            return (
              <WhyStatecsCard
                title={title}
                subtitle={subtitle}
                path={path}
                key={index}
              />
            );
          })}
        </Carousel>
      </Row>
    </div>
  );
};

export default WhyStatecsCarousel;
