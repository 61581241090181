import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Dropdown, Menu } from "antd";

import "./HeaderItem.scss";

const { Text } = Typography;

const HeaderItem = ({ page, path, content, type, cascadeItems, icon }) => {
  const [rotate, setRotate] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  switch (page) {
    case true:
      switch (type) {
        case "cascade":
          return (
            <Dropdown
              overlay={
                <Menu
                  items={cascadeItems?.map(({ content, href }, index) => {
                    return {
                      label: <Link to={href}>{content}</Link>,
                      key: `${index}`,
                    };
                  })}
                />
              }
              trigger={["hover"]}
              placement="bottom"
              onVisibleChange={() => {
                setRotate(!rotate);
              }}
            >
              <Link to={path}>
                <div className="item">
                  <Text className="headerItemDark">{content}</Text>{" "}
                  {icon ? (
                    <div
                      className="iconDark"
                      style={{
                        transform: rotate ? "rotate(90deg)" : "rotate(0deg)",
                      }}
                    >
                      {icon}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            </Dropdown>
          );

        default:
          return (
            <Link to={path} className="link" onClick={scrollToTop}>
              <Text className="headerItemDark">{content}</Text>
            </Link>
          );
      }

    default:
      switch (type) {
        case "cascade":
          return (
            <Dropdown
              overlay={
                <Menu
                  items={cascadeItems?.map(({ content, href }, index) => {
                    return {
                      label: <a href={href}>{content}</a>,
                      key: `${index}`,
                    };
                  })}
                />
              }
              trigger={["hover"]}
              placement="bottom"
              onVisibleChange={() => {
                setRotate(!rotate);
              }}
            >
              <Link to={path}>
                <div className="item">
                  <Text className="headerItem">{content}</Text>

                  {icon ? (
                    <div
                      className="icon"
                      style={{
                        transform: rotate ? "rotate(90deg)" : "rotate(0deg)",
                      }}
                    >
                      {icon}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            </Dropdown>
          );

        default:
          return (
            <Link to={path} className="link" onClick={scrollToTop}>
              <Text className="headerItem">{content}</Text>
            </Link>
          );
      }
  }
};

export default HeaderItem;
