import React from "react";

import { Typography } from "antd";

import "./PrivacyPolicy.scss";

const { Text } = Typography;

const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicyPage">
      <div className="PrivacyPolicyContainer">
        <Text className="PrivacyPolicySubtitle">
          STATECS respects the relationship we have with our customers,
          therefore it is dedicated to maintaining and defending your right to
          privacy.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          The terms on which we will process any personal information you give
          us or that we acquire from you (together with any other documents
          referred to on this page and our website terms of use) are outlined in
          this policy. "We," "our," and "us" refer to STATECS in this document.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          For further information on our policies and procedures surrounding
          your personal data and how we will handle it, please read the
          following carefully.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Any future modifications to our privacy policy will be announced on
          this page and, if necessary, notified to you by email.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          To better understand Our policies and procedures surrounding your
          personal information and how We will treat it, please carefully read
          the following.
        </Text>

        <Text className="PrivacyPolicySectionTitle">1. Introduction</Text>
        <Text className="PrivacyPolicySubtitle">
          This notice explains our policy regarding all the information we keep
          on file about you. It outlines the terms and circumstances for how we
          may treat any information you give us or that we obtain from you. It
          includes the information that could identify you (“personal
          information”) and information that could not. The term "process" is
          used in the legislation and this notice refers to the gathering,
          storing, transferring, using, or other action on the information.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          The goal of STATECS is to accelerate the international development of
          secure and efficient medical therapies. At all times, STATECS is
          dedicated to carrying out clinical trials in a way that strictly
          complies with all national and international ethical standards and
          guidelines governing clinical trials. Clinical trial participants,
          clinical trial investigators, vendors, support staff, and workers must
          have their personal data collected, recorded, processed, stored, and
          transmitted in order to comply with the requirements for clinical
          trials.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          STATECS is dedicated to upholding the privacy of people of all
          nationalities when processing their personal data, acknowledging the
          fundamental rights to the lawfulness, justice, and transparency. With
          default and design, STATECS abides by the principles of data privacy,
          including data reduction whenever practical.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          2. Gathering Personal Information Directly from You
        </Text>

        <Text className="PrivacyPolicySubtitle">
          You can browse the Website anonymously, without disclosing any
          Personal Information. However, there may be instances in which We need
          personal information about you or in which you choose to give us such
          information. Such Personal Information is gathered when provided by
          You and is governed by the guidelines outlined in this Privacy
          Statement.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We must process the information you provide us with in order to
          fulfill our obligations under that contract. It's possible that some
          of this data contains personal information.
        </Text>

        <Text className="PrivacyPolicyTitle">
          We may gather and process the following Personal Information about
          You:
        </Text>

        <ul>
          <li>
            Information that You submit by filling out forms on the Website,
            such as Your contact information or Personal Information.
          </li>
          <li>
            Regarding your usage of our website's careers section and the data
            you gave during the hiring and onboarding processes (such as
            application materials, offer letters, etc.). You must read and
            accept the individual terms of use before using any of the services
            offered on that portion of the website.
          </li>
          <li>
            We might also request personal information from you (such as when
            You report a problem with the Website).
          </li>
          <li>
            We may maintain a record of any written communications you send to
            us via email or other electronic means.
          </li>
          <li>
            Information about your visits to the website, information gathered
            while you browse it, and information about the resources you access.
            Use of this data will be in compliance with this Privacy Statement
            and may include, but is not limited to, usage details, IP addresses,
            location data, and other communication data.
          </li>
        </ul>

        <Text className="PrivacyPolicyTitle">
          We may gather and process the following Personal Information about
          You:
        </Text>

        <ul>
          <li>To verify your identity</li>
          <li>To provide services to you</li>
          <li>To notify you about the updates of our websites</li>
          <li>
            We may also use your data to send you information about services
            that may interest you. We may do so by mail, phone, email, or other
            means (for example blog posts, webinars, training, and case analyses
            that we believe to be pertinent to your interests and based on the
            data that you have provided).
          </li>
        </ul>
        <Text className="PrivacyPolicySubtitle">
          We process this information under the premise that there is an
          existing contract between us or that you have given us permission to
          do so in advance of a formal agreement.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Additionally, we might compile this data in a broad sense and use it
          to track how well we're doing at a specific service we offer, for
          example, and use that information to provide class information. You
          won't be able to be identified personally if we utilize the
          information for this reason
        </Text>

        <Text className="PrivacyPolicyTitle">
          2. Information we gather with your agreement
        </Text>
        <Text className="PrivacyPolicySubtitle">
          When you take specific actions when there isn't otherwise a legal
          agreement between us, including browsing our website or asking us for
          more details about our company, including job openings, our products,
          and our services, We will request you give us permission to handle
          personal information.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Wherever possible, we try to get your express permission before
          processing personal information, such as when we ask if you agree to
          let us use cookies or if you want to receive email marketing
          communications.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          You may on occasion offer your consent without explicitly saying it,
          such as when you send us an email and reasonably anticipate a response
          from us.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We do not use your information in any way that might individually
          identify you unless you have given us permission to use it for a
          particular reason. We might combine it broadly and utilize it to offer
          class information, such as tracking how well a specific page performs
          on our website.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          3. Information gathered about clinical trial subjects
        </Text>

        <Text className="PrivacyPolicySubtitle">
          STATECS processes pseudonymized health and medical data pertaining to
          participants in clinical studies. At the study locations, the
          researchers' personnel and they themselves gather this data. The
          clinical study's investigators are in charge of making sure that
          subjects understand and consent to the collection of sensitive
          personal data about their health, including the transfer of such
          pseudonymized data to outside parties who could be helping with the
          clinical research.
        </Text>

        <Text className="PrivacyPolicyTitle">
          3. Personal Data of Business Partners
        </Text>

        <Text className="PrivacyPolicySubtitle">
          Vendors and business partners who are working on a clinical trial for
          STATECS provide personal information. The execution of STATECS
          contracts with these people and their employers depends on this
          processing, which may also be necessary for the submission of clinical
          trial data to governmental and regulatory bodies, and ethical
          committees.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          The purpose of gathering physician investigator data is to satisfy a
          legal requirement that investigators must be qualified to oversee a
          clinical study. The performance of a contract between STATECS
          (directly or on behalf of the Sponsor) and the site serves as the
          foundation for gathering information from site personnel and
          investigative staff. When applicable, STATECS complies with all
          requirements to disclose information about the processing or transfer
          of this personal data in a transparent manner
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          4. IT and Security Protocols
        </Text>
        <Text className="PrivacyPolicySubtitle">
          To protect and secure the personal data kept on its systems, STATECS
          has put in place physical, electronic, and organizational procedures.
          To safeguard data against loss, misuse, unauthorized access,
          disclosure, alteration, and destruction, STATECS uses encryption,
          firewalls, access controls, and other techniques. Technical and
          physical controls are used to regulate access to STATECS facilities.
          In case its systems are harmed or destroyed, STATECS keeps a disaster
          recovery strategy and a system backup plan. Every employee receives
          security training, and STATECS requires them to annually examine and
          comprehend the pertinent international data protection requirements.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          5. Information submitted with the intention that a third party will
          also receive it
        </Text>
        <Text className="PrivacyPolicySubtitle">
          You can publish content on our website with the intention of it being
          viewed, copied, or utilized by others.
        </Text>

        <Text className="PrivacyPolicyTitle">Examples comprise:</Text>

        <ul>
          <li>Writing a comment on our site </li>
        </ul>

        <Text className="PrivacyPolicySubtitle">
          When publishing personal information on our website, you confirm to
          understand that your information will be public and can be used by
          others.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Except to permit it to be displayed or shared, we do not expressly use
          this information.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          As soon as your information is made available to the public, we have
          no control over what any particular third party may do with it. We
          never take responsibility for their behavior.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We may also disclose Personal Information to a third party in
          connection with the sale, or other transfer of the Website's business,
          to which the information belongs. In this situation, We will demand
          that the third party accept Our Terms of Use regarding the handling of
          Personal Information.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Additionally, we might divulge general, non-personal data on how
          people use the website to unrelated parties. No personally
          identifiable data about our users is included in this aggregate data.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          6. Information Storage and Security
        </Text>

        <Text className="PrivacyPolicySubtitle">
          For any unlawful or unauthorized use of our website, we retain the
          right to pursue necessary legal action, including without restriction,
          referral to law enforcement. Additionally, We reserve the right to
          take whatever steps are necessary to stop the unlawful use of Our
          intellectual property.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We may cooperate with any law enforcement officials or court order
          asking or instructing Us to reveal someone's identity or locate them
          in order to stop, detect, or prosecute criminal activity. We might
          also be forced by law to share information about You or how You use
          our website in other situations. You release Us from liability for any
          claims originating from such disclosures and from any actions taken as
          a result of inquiries made by Us or law enforcement officials.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Any complaints or claimed privacy statement violations may be
          investigated, and we may then take whatever action we feel necessary
          (which may include, without limitation, removing any of Your
          information, issuing warnings, suspending, restricting, or terminating
          Your access to this Website). Furthermore, We retain the right, in Our
          sole discretion, to suspend, restrict, or terminate Your access to
          this Website whenever We see fit, without prior notice, if We have
          good reason to suspect that You have violated this Privacy Statement
          or Our Terms of Use.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          7. Applying for jobs and trainings
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We may preserve any information you provide us in connection with a
          job application or training participation for up to a year in case we
          need to get in touch with you later.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          8. Contacting our customer service team
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We gather the information you provide when you contact us, whether
          it's over the phone, online, or via email, so we can respond with the
          details you require.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          In order to improve the effectiveness of our business, we record your
          request and our response.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          In order to provide you with high-quality service, we may preserve
          personally identifiable information connected to your messages, such
          as your name and email address, in order to be able to track our
          conversations with you.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          9. Complaints and reports
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We keep a record of all the details you have provided to us when you
          file a complaint.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We use such information in order to address your complaint. In order
          to evaluate the quality of our services, we may also create statistics
          based on the data from this source, but never in a way that might be
          used to identify you or any other individual.
        </Text>

        <Text className="PrivacyPolicySectionTitle">10. Cookies</Text>
        <Text className="PrivacyPolicySubtitle">
          STATECS uses cookies to gather information about visitors to our
          website. Cookies gathered from the STATECS website are used to:
        </Text>

        <Text className="PrivacyPolicyText">
          (1) make some tools and features on the site functional;
        </Text>
        <Text className="PrivacyPolicyText">
          (2) make the site easier to navigate;
        </Text>
        <Text className="PrivacyPolicyText">
          (3) keep track of the resources and data used on the site;
        </Text>
        <Text className="PrivacyPolicyText">
          (4) advertise STATECS solutions and services;
        </Text>
        <Text className="PrivacyPolicySubtitle">
          (5) remember computer settings. Other types of non-personal
          information that STATECS gathers include browsers used to access our
          website, search keywords used to reach it, and traffic referrals and
          links to our website. Within our tracking system, cookies are not kept
          forever.
        </Text>

        <Text className="PrivacyPolicySubtitle">
          To track visitors’ number on the website, STATECS also employs Google
          Analytics' services. In order to enhance the website's functionality,
          usage data is gathered and aggregated. If you do not want your data to
          be included in this aggregated data collected by Google Analytics, you
          can download and install Google's "Google Analytics Opt-out Browser
          Add-on" from this
          <a target="_blanck" href="https://tools.google.com/dlpage/gaoptout">
            link.
          </a>
        </Text>
        <Text className="PrivacyPolicySubtitle">
          To prohibit the use of cookies and the inclusion of your information
          in Google Analytics' aggregated data, you must change the properties
          on your web browser. However after doing so, you might not be able to
          access all or portions of our site if you modify your browser's
          settings to reject all cookies (including necessary cookies). Our
          system will start sending cookies as soon as you visit our site unless
          you've changed the settings in your browser to make it reject cookies.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Until you use your browser to delete the cookies, they will remain on
          your computer. Remembering your browser settings for the next time you
          visit our site saves you time by preventing you from having to fill
          out several forms with the same information. We check your permission
          to use cookies when you first visit our website. If you decide not to
          accept them, we won't use them for your visit other than to note that
          you haven't given permission for their use in any other way.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We may use this information for the following purposes:
        </Text>

        <Text className="PrivacyPolicySectionTitle">11. External Links</Text>
        <Text className="PrivacyPolicySubtitle">
          STATECS website may include external links to a variety of websites
          that could offer helpful information as a convenience to our site
          users. External links on the STATECS website that take you to a
          non-STATECS website may include information or privacy practices that
          differ from those of our website. After leaving STATECS website, you
          can evaluate the privacy policies of these websites and/or request
          particular information regarding their privacy policies and practices.
          A non-STATECS website's privacy policies or its content are not the
          responsibility of STATECS. STATECS has no control over or influence
          over whether our business partners' or websites with whom we have
          links utilize or accept cookies.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          12. Updates and modifications
        </Text>
        <Text className="PrivacyPolicySubtitle">
          This privacy statement may occasionally be updated. For Your
          convenience, we will post the amended Privacy Statement on this
          website as soon as we do so. The Privacy Statement is subject to
          revision and additions, and those changes take effect when they are
          posted. In order to determine whether We have modified How We Use Your
          Personal Information, please periodically review the Privacy
          Statement.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          13. Your Rights Regarding Your Personal Information
        </Text>
        <Text className="PrivacyPolicySubtitle">
          According to legislation, you have a number of rights regarding your
          personal information
        </Text>

        <Text className="PrivacyPolicyTitle">
          These include the following rights to:
        </Text>

        <ul>
          <li>Access Your personal information</li>
          <li>Make changes regarding Your personal information</li>
          <li>Delete your information</li>
          <li>Restrict or object to Our use of Your personal information</li>
          <li>
            Request to receive Your personal Information in a usable electronic
            format and transmit it to a third party (right to data portability);
            and, if Your country has a local data protection authority, file a
            complaint with it.
          </li>
        </ul>

        <Text className="PrivacyPolicySubtitle">
          If Your personal information changes, or if the personal information
          we currently have on file for you is incorrect, we encourage you to
          contact us so we can update or amend it. Please be aware that We will
          probably need further details from You in order to fulfill your
          requests.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Please be aware that if You ask for Your personal information to be
          deleted, We may temporarily stop sending you items while We update Our
          lists. Your records will thereafter be permanently removed from Our
          systems.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          14. Privacy of Children
        </Text>
        <Text className="PrivacyPolicySubtitle">
          We do not market to kids and we don't sell things or offer services
          that kids can buy. Regardless of age, we gather information on
          everyone who uses or visits these places, and we anticipate that some
          of those people will be kids.
        </Text>

        <Text className="PrivacyPolicySectionTitle">
          15. Contact Information
        </Text>
        <Text className="PrivacyPolicySubtitle">
          Please write to Us at info@statecs.com if You have any inquiries or
          concerns about how Your personal information is used.
        </Text>
        <Text className="PrivacyPolicySubtitle">
          This Privacy statement has been last revised in September 2022.
        </Text>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
