export const WHY_STATECS_CONSTANTS = [
  {
    title: "TRUST",
    path: "about-us",
    subtitle:
      "Trust is one of the most crucial aspects in any successful cooperation. Years of hard work and dedication helped us build trust and have long-term partnership with our clients.",
  },
  {
    title: "QUALITY",
    path: "about-us",
    subtitle:
      "Our long experience in the field showed us that quality work always pays back. Whether it pays back through achievements or client’s gratitude it always helps us move further.",
  },
  {
    title: "CARE",
    path: "about-us",
    subtitle:
      "Care is the cornerstone of our organization. Having a caring and transparent workplace helps us build close relationships not only within our team but also with our clients and patients.",
  },
  {
    title: "LOYALTY",
    path: "about-us",
    subtitle:
      "Loyalty is in the roots of our company whether it has to do with our team members, partners or company values. It is through our loyalty to core values that we meet success.",
  },
];
