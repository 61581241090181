import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import rightArrow from "../../../../../assets/images/arrow-right-white.svg";
import "./TrainingsCard.scss";

const { Text } = Typography;

const TrainingsCard = ({ img, title, subtitle, path }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="trainingsCard">
      {img && <img src={img} alt="training-icon" className="trainingIcon" />}
      <Text className="trainingsCardTitle">{title ? title : ""}</Text>
      <Text className="trainingsCardSubtitle">{subtitle ? subtitle : ""}</Text>
      <div className="seeMoreContainer">
        <Link to={path} className="seeMoreButton" onClick={scrollToTop}>
          <Text className="seeMoreButtonContent">See more</Text>
          <img src={rightArrow} alt="srrow-icon" />
        </Link>
      </div>
    </div>
  );
};

export default TrainingsCard;
