import React from "react";

import { Typography } from "antd";

import "./TherapeutixExperienceContent.scss";

const { Text } = Typography;

const TherapeutixExperienceContent = () => {
  return (
    <div className="therapeutix-experience">
      <Text className="text">
        STATECS has experience in a wide range of therapeutic areas. Check them
        out below:
      </Text>
      <Text className="title">Oncology</Text>
      <Text className="text">
        Medical data is a serious thing to handle regardless of the therapeutic
        area you are in as in every case human lives are at stake. This is why,
        to carry out your oncology trials, you should choose an organization
        that has experience in that area and offers more than the traditional
        full-service data collection and management services.
      </Text>
      <Text className="text">
        At STATECS, we have deep insights into oncology trials as we have worked
        with many oncology-centered drug development companies in the past. Our
        expertise in this field allows us to proudly state that we can
        considerably improve your trial process making it error-free and
        statistically sound.
      </Text>
      <Text className="title">Here is what we offer:</Text>
      <Text className="subtext">
        Highly Complex Datasets: Production of complex datasets that contain
        data from numerous sources in large volumes and standardization of this
        data for successful clinical trial reporting.
      </Text>
      <Text className="subtext">
        Collaboration with Third-Party Vendors: Assistance in the management of
        your third-party vendors: providers of tumor markers, biomarkers, safety
        laboratory data, etc. Ensuring all the required data for your oncology
        trials are gathered in a single place for faster access and analysis.
        Data Standardization: Let us help you comply with CDISC standards and
        produce a consolidated database in the common standardized formats
        (SDTMs &#38; ADaMs). Being well aware of the recent CDISC guidelines and
        regulatory requirements as well as having deep knowledge of the latest
        medical dictionaries, you can rest assured that our experts will highly
        ease your clinical trial process by providing you with data consistency
        and standardization.
      </Text>
      <Text className="subtext">
        Dealing with Large Volumes of Data: Our clinical data management team
        will handle your increased volume of data with the help of the latest
        data collection methods. Additionally, our experienced statisticians
        will capture all the relevant data from any mobile device or eSource
        technology according to your trial design. We will make your clinical
        trial reporting compatible with big theories and with the latest
        statistical programming methodologies that can handle large volumes of
        data. Real-World Data Analysis: We come with experience in supporting
        regulatory approval processes for studies that have had real-world data
        as the basis of their trials. Regulatory Submission Timelines: STATECS
        can help you manage tight regulatory submission deadlines without
        affecting the quality of your work.
      </Text>
      <Text className="subtext">
        Choosing the Right Oncology Study Designs: Our simulation-based methods
        will help you choose the right oncology study design and sample size for
        Phase 1 dose-determining processes.
      </Text>
      <Text className="subtext">
        Additional Reporting: We will cover all your reporting needs for data
        and safety monitoring committees. Efficacy Endpoints: Our personalized
        trials will help you measure your goal endpoints and understand the
        efficiency of your treatment. Real-Time Oncology Review: Get your
        treatments approved faster.
      </Text>
      <Text className="text">
        We will walk you through the oncology development stages: from study
        onset and conduct to data analysis, regulatory submissions, post
        submission, and approval processes.
      </Text>
      <Text className="title">Cardiovascular &#38; Metabolic</Text>
      <Text className="text">
        Stroke, coronary heart disease, acute coronary syndromes, heart failure:
        STATECS has worked with therapeutic organizations studying the
        above-listed cardiovascular and metabolic diseases.
      </Text>
      <Text className="title">Respiratory &#38; Immuno-Inflammatory</Text>
      <Text className="text">
        STATECS has in-depth expertise in working on respiratory and
        immuno-inflammatory cases which include various studies on rhinitis,
        asthma, psoriasis, renal and liver transplantation, etc.
      </Text>
      <Text className="title">Dermatology</Text>
      <Text className="text">
        STATECS has experience in handling data from clinical trials on acne,
        dermatitis, a topic eczema, psoriasis lesions, etc.
      </Text>
      <Text className="title">Ophthalmology</Text>
      <Text className="text">
        Yet another area where we managed to have our fair share of knowledge
        and skill investment. We worked on clinical trials that focused on
        age-related macular degeneration, contact lenses and interocular lenses
        applications, and diabetic macular edema.
      </Text>
      <Text className="title">Hematology</Text>
      <Text className="text">
        STATECS has worked on multiple clinical trials dealing with hematology
        at different trial phases.
      </Text>
      <Text className="title">Vaccine &#38; Virology</Text>
      <Text className="text">
        Our team has helped conduct various studies on vaccines aiming to find
        out antibody response and clinical signs. Considering the current
        pandemic, we see this area as one of the most important in clinical
        research and strive to provide high-quality data management services to
        companies focusing on the development and trial of vaccines.
      </Text>
      <Text className="title">Nephrology, Renal, and Urology</Text>
      <Text className="text">
        Chronic Kidney disease, Anaemia, and Benign Prostatic Hyperplasia have
        been under investigation by the companies we collaborated with to help
        in the success of their clinical trials.
      </Text>
    </div>
  );
};

export default TherapeutixExperienceContent;
