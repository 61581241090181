import React from "react";

import { Typography } from "antd";

import "./ServiceCard.scss";

const { Text } = Typography;

const ServiceCard = ({ content }) => {
  return (
    <div className="serviceCard">
      <Text className="serviceCardContent">{content}</Text>
    </div>
  );
};

export default ServiceCard;
