import firstIcon from "../../assets/images/services-icon-first.png";
import secondIcon from "../../assets/images/services-icon-second.png";
import thirdIcon from "../../assets/images/services-icon-third.svg";
import fourthIcon from "../../assets/images/services-icon-fourth.png";
import fivethIcon from "../../assets/images/our-services-management-icon.png";
import sixthIcon from "../../assets/images/our-services-medical-icon.png";
import seventhIcon from "../../assets/images/our-services-regulatory-affairs- icons.png";
import eighthIcon from "../../assets/images/our-services-statistics-icon.svg";
import tenthIcon from "../../assets/images/personalized-strategy.png";
import eleventhIcon from "../../assets/images/efficient-results.png";
import twelfthIcon from "../../assets/images/services-icon-twelfth.png";

export const SERVICES_ADVANTAGE_CONSTANTS = [
  {
    icon: firstIcon,
    title: "High Quality Service",
    subtitle:
      "We always strive for high quality and 100% on time metrics for all our clients. ",
  },
  {
    icon: secondIcon,
    title: "Customized Approach",
    subtitle:
      "At STATECS, we prefer a customer-centric approach over one size fits all mindset.  ",
  },
  {
    icon: thirdIcon,
    title: "24/7 Customer Support",
    subtitle:
      "With us you get professional 24/7 customer support ready to answer all your queries.",
  },
];

export const ADVANTAGE_MEDICAL_WRITING_CONSTANTS = [
  {
    icon: eleventhIcon,
    title: "Efficient Results",
    subtitle:
      "No matter what we do at STATECS, we do it with a result-oriented approach. By choosing us, you get efficient work results every step of the process.",
  },
  {
    icon: tenthIcon,
    title: "Personalized Strategy",
    subtitle:
      "At STATECS, we thoroughly analyze the project - its strengths and weaknesses, then we come up with a personalized strategy covering your project's specific needs and goals.",
  },
];

export const ADVANTAGE_MEDICAL_WRITING_SUBCARDS_CONSTANTS = [
  {
    icon: eighthIcon,
    subtitle: "Statistical Programming",
    path: "/services/statistical-programming",
  },
  {
    icon: fivethIcon,
    subtitle: "Clinical Data Management",
    path: "/services/clinical-data-management",
  },
  {
    icon: seventhIcon,
    subtitle: "Regulatory Affairs",
    path: "/services/regulatory-affairs",
  },
];

export const ADVANTAGE_STATISTICAL_PROGRAMMING_SERVICES_CONSTANTS = [
  {
    icon: secondIcon,
    subtitle:
      "In-depth knowledge of Statistical Programming requirements, standards, and field insights.",
  },
  {
    icon: firstIcon,
    subtitle:
      "High-quality service and personalized working approach for every client.",
  },
  {
    icon: fourthIcon,
    subtitle:
      "An experienced and enthusiastic team of professionals motivated to utilize their knowledge and experience.",
  },
];

export const ADVANTAGE_STATISTICAL_PROGRAMMING_SERVICES_SUBCARDS_CONSTANTS = [
  {
    icon: fivethIcon,
    subtitle: "Clinical Data Management",
    path: "/services/clinical-data-management",
  },
  {
    icon: sixthIcon,
    subtitle: "Medical Writing",
    path: "/services/medical-writing",
  },
  {
    icon: seventhIcon,
    subtitle: "Regulatory Affairs",
    path: "/services/regulatory-affairs",
  },
];

export const ADVANTAGE_CLINICAL_DATA_MANAGEMENT_CONSTANTS = [
  {
    icon: firstIcon,
    subtitle:
      "At STATECS, we are in charge of delivering quality services and also guiding our clients through the process. We want our clients to be fully engaged in all aspects and stages of the processes.",
  },
  {
    icon: secondIcon,
    subtitle:
      "Being experts at clinical data management, we know the process from the inside out, so we will provide you with a customized strategy that is most suitable for your project.",
  },
  {
    icon: fourthIcon,
    subtitle:
      "Years of experience in different therapeutic areas, different types of studies, and EDC systems help us deliver efficient and valuable data management services.",
  },
];

export const ADVANTAGE_CLINICAL_DATA_MANAGEMENT_SUBCARDS_CONSTANTS = [
  {
    icon: eighthIcon,
    subtitle: "Statistical Programming",
    path: "/services/statistical-programming",
  },
  {
    icon: sixthIcon,
    subtitle: "Medical Writing",
    path: "/services/medical-writing",
  },
  {
    icon: seventhIcon,
    subtitle: "Regulatory Affairs",
    path: "/services/regulatory-affairs",
  },
];

export const ADVANTAGE_REGULATORY_AFFAIRS_CONSTANTS = [
  {
    icon: secondIcon,
    subtitle:
      "In-depth expertise in regulatory affairs and comprehensive knowledge of all regulatory legislation changes and requirements both regionally and globally.",
  },
  {
    icon: twelfthIcon,
    subtitle:
      "Insights into the latest clinical trial and submission requirements and large practice of successfully handling the end-to-end lifecycle of product submission and development.",
  },
  {
    icon: fourthIcon,
    subtitle:
      "Extensive experience in supporting numerous leading pharmaceutical companies to comply with the necessary regulations and get approved by the FDA.",
  },
];

export const ADVANTAGE_REGULATORY_AFFAIRS_SUBCARDS_CONSTANTS = [
  {
    icon: eighthIcon,
    subtitle: "Statistical Programming",
    path: "/services/statistical-programming",
  },
  {
    icon: fivethIcon,
    subtitle: "Clinical Data Management",
    path: "/services/clinical-data-management",
  },
  {
    icon: sixthIcon,
    subtitle: "Medical Writing",
    path: "/services/medical-writing",
  },
];
