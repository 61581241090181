import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import DataManagementTraining from "./DataManagementTraining/DataManagementTraining";

import "./DataManagementTrainingPage.scss";

const { Text } = Typography;

const DataManagementTrainingPage = () => {
  return (
    <div className="dataManagementTrainingPage">
      <Helmet>
        <meta
          name="title"
          content="Data Management Training Programs | STATECS"
        />
        <meta
          name="description"
          content="STATECS offers a data management training program that gives  a full overview of clinical data collection and management principles."
        />
        <title>Data Management Training Programs | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="dataManagementPageHeader">
        <div className="dataManagementPageHeaderContainer">
          <h1 className="dataManagementPageHeaderTitle">
            DATA MANAGEMENT TRAINING
          </h1>
          <Text className="dataManagementPageHeaderSubtitle">
            Every company, organization, or business generates a huge amount of
            data every day, and with so much data coming in from everywhere,
            managing and analyzing it is a must for the collection of
            information. Therefore, in today's world, database systems have
            become an integral part of each organization regardless of the
            field. With that being said, it is not just about accessing the
            data, but more about having the skills and knowledge of managing
            data properly. To help beginner enthusiasts with this, STATECS
            offers a data management training program designed on years of
            experience and practice.
          </Text>
          <Link
            to="/trainings/apply-data-management-trainings"
            className="dataManagementApplyButton"
          >
            Apply
          </Link>
        </div>
      </div>
      <DataManagementTraining />
    </div>
  );
};

export default DataManagementTrainingPage;
