import React from "react";

import { Typography } from "antd";

import "./OncologyClinicalResearchOrganizationContent.scss";

const { Text } = Typography;

const OncologyClinicalResearchOrganizationContent = () => {
  return (
    <div className="oncology-clinical-research-organization">
      <Text className="text">
        In clinical research, the Bayesian statistics method offers a framework
        for drawing conclusions about treatment results based on data gathered
        from sources other than the specific clinical study in question. Making
        efficient and prompt inferences about the safety and/or efficacy of a
        treatment or therapy can be accomplished by combining prior information
        (from prior trials, scientific research, or "expert opinion") with
        information as it is gathered during a trial as well as with the usual
        data available upon trial completion.
      </Text>
      <Text className="text">
        Due to the fact that clinical trials are pretty expensive in their
        nature, more and more pharmaceutical companies shift their interest
        toward Bayesian methods and with consistently growing algorithmic
        development and improved computational speeds, these methods are
        becoming increasingly more accessible and accepted.
      </Text>
      <Text className="title">
        THE ADVANTAGES OF THE BAYESIAN METHOD IN CLINICAL DESIGN
      </Text>

      <ul className="list">
        <li className="list-item">
          LESS COSTS - By using the prior, relevant information significantly
          lessens the costs of sample sizes (or shorten trial lengths) that are
          required to achieve the desired goals. This helps to lower the overall
          development costs.
        </li>
        <li className="list-item">
          ENHANCED TRIAL DESIGN EFFICIENCY - The trial design can be modified to
          increase design efficiency by using real-time data as they are
          gathered (either through interim analyses or continuous reassessment
          approaches). The experiment could be terminated early (due to
          documented futility/efficacy), additional treatment arms could be
          added, unsuccessful treatment arms might be withdrawn, or the
          randomization of therapy could be changed to favor the more effective
          treatment, among other options. Due to the improved patient resource
          use and potential for improved patient care, such changes are
          appealing to both researchers and patients. The Bayesian framework
          makes it simpler to create adaptive clinical trial designs.
        </li>
        <li className="list-item">
          In situations involving uncommon diseases, where typical sample sizes
          can be challenging, if not impossible, to attain, frequentist designs
          may not always be effective. Bayesian methods offer special advantages
          in these situations. The frequentist methodology of typical hypothesis
          testing in a clinical trial may not always be the optimum strategy,
          and Bayesian methods allow for the consideration of alternate
          strategies.
        </li>
      </ul>

      <Text className="title">WHAT WE OFFER</Text>
      <Text className="text">
        The statistical consultants of STATECS have conducted several Bayesian
        trial analyses and have large experience with clinical research designs.
        We offer professional guidance on Bayesian adaptive designs using a
        strategy that often includes the following:
      </Text>

      <ul className="list">
        <li className="list-item">
          Choosing an acceptable prior based on prior research and other
          available information about the treatment or therapy
        </li>
        <li className="list-item">
          Using statistical modeling to guide the safe and effective escalation
          of doses toward a Maximum Tolerable Dose (MTD)
        </li>
        <li className="list-item">
          The gathering of solid evidence to support the efficacy and safety of
          a treatment; using an adaptive strategy to enable more effective use
          of finances.
        </li>
        <li className="list-item">
          A flexible approach that minimizes sample numbers while providing the
          best care for patients
        </li>
        <li className="list-item">
          Interim analyses that employ predictive probabilities are used to end
          a study early if the treatment is showing to be unsuccessful (futile)
          or if it has already established its effectiveness (efficacy)
        </li>
        <li className="list-item">
          In-depth information and analysis on whether an adaptive design or
          Bayesian technique is appropriate for the specific research program
          and design stage of that program
        </li>
      </ul>
    </div>
  );
};

export default OncologyClinicalResearchOrganizationContent;
