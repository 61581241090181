import { TiChevronRight } from "react-icons/ti";

export const HEADER_ITEMS = [
  { path: "/", content: "Home" },
  { path: "/about-us", content: "About us" },
  {
    path: "/services",
    content: "Services",
    icon: <TiChevronRight />,
    type: "cascade",
    cascadeItems: [
      { content: "Medical Writing", href: "/services/medical-writing" },
      {
        content: "Clinical Data Management",
        href: "/services/clinical-data-management",
      },
      {
        content: "Statistical Programming",
        href: "/services/statistical-programming",
      },
      { content: "Regulatory Affairs", href: "/services/regulatory-affairs" },
    ],
  },
  { path: "/expertise", content: "Expertise" },
  {
    path: "/trainings",
    content: "Trainings",
    icon: <TiChevronRight />,
    type: "cascade",
    cascadeItems: [
      { content: "SAS Training", href: "/trainings/sas-trainings" },
      {
        content: "DM Training",
        href: "/trainings/data-management-trainings",
      },
    ],
  },
  { path: "/blog", content: "Blog" },
  { path: "/contact-us", content: "Contact us" },
  { path: "/careers", content: "Join us" },
];
