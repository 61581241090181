import { getSASTrainingsActionEnum } from "../actionType";
import { GetSASTrainings } from "../service/service";

export const getSASTrainingsAction = () => async (dispatch) => {
  dispatch({ type: getSASTrainingsActionEnum.IS_LOADING_SAS_TRAININGS });
  try {
    const data = await GetSASTrainings();

    dispatch({
      type: getSASTrainingsActionEnum.GET_SAS_TRAININGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: getSASTrainingsActionEnum.GET_SAS_TRAININGS_ERROR,
      error: error,
    });
  }
};
