import React from "react";

import { Typography } from "antd";

import ourVision from "../../../../assets/images/our-vision.png";
import "./OurVision.scss";

const { Text } = Typography;

const OurVision = () => {
  return (
    <div className="OurVision">
      <img src={ourVision} alt="our-vision" className="OurVisionCover" />
      <div className="OurVisionTextContent">
        <Text className="OurVisionTextContentTitle">OUR VISION</Text>
        <Text className="OurVisionTextContentSubtitle">
          Regardless of what we do and how much we grow, we always strive to
          contribute to the enhancement of a better healthcare system and help
          people by providing them with innovative clinical solutions. With this
          goal in mind, we do our best to expand our team with more motivated
          enthusiasts to become an internationally leading Contract Research
          Organization (CRO) trusted by more organizations within the industry.
        </Text>
      </div>
    </div>
  );
};

export default OurVision;
