import { postContactForTrainingsActionEnum } from "../actionType";
import { ContactForTrainings } from "../service/service";

export const applyTrainingsAction = (data, callback) => async (dispatch) => {
  dispatch({
    type: postContactForTrainingsActionEnum.IS_LOADING_CONTACT_FOR_TRAININGS,
  });
  try {
    const response = await ContactForTrainings(data);

    dispatch({
      type: postContactForTrainingsActionEnum.POST_CONTACT_FOR_TRAININGS_SUCCESS,
      payloda: response,
    });
    callback({ type: "success" });
  } catch (error) {
    dispatch({
      type: postContactForTrainingsActionEnum.POST_CONTACT_FOR_TRAININGS_ERROR,
      payload: error,
    });
    callback({ type: "faild" });
  }
};
