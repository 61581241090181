import React from "react";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import TrainingCalendar from "../../shared/components/TrainingCalendar/TrainingCalendar";
import TrainingApplyForm from "./TrainingApplyForm/TrainingApplyForm";

import "./ApplyDMTrainingPage.scss";

const { Text } = Typography;

const ApplyDMTrainingPage = () => {
  return (
    <div className="applyDMTrainingPage">
      <Helmet>
        <meta
          name="title"
          content="Data Management Training Programs | STATECS"
        />
        <meta
          name="description"
          content="STATECS offers a data management training program that gives  a full overview of clinical data collection and management principles."
        />
        <title>Data Management Training Programs | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="applyDMTrainingPageHeader">
        <div className="applyDMTrainingPageHeaderBackground"></div>
        <div className="applyDMTrainingPageHeaderContainer">
          <Text className="applyDMTrainingPageHeaderTitle">
            DATA MANAGEMENT
          </Text>
          <Text className="applyDMTrainingPageHeaderSubtitle">
            Every company, organization, or business generates a huge amount of
            data every day, and with so much data coming in from everywhere,
            managing and analyzing it is a must for the collection of
            information. Therefore, in today's world, database systems have
            become an integral part of each organization regardless of the
            field.
          </Text>
          <TrainingCalendar />
          <Link
            to="trainingApplyForm"
            smooth="true"
            offset={-90}
            duration={800}
            className="applyDMTrainingApplyButton"
          >
            Apply
          </Link>
        </div>
      </div>
      <TrainingApplyForm />
    </div>
  );
};

export default ApplyDMTrainingPage;
