import React from "react";
import { NavLink } from "react-router-dom";

import { Typography } from "antd";
import AfterTrainingsCard from "../../../shared/components/AfterTrainingsCard/AfterTrainingsCard";
import AfterTrainingCarousel from "./AfterTrainingCarousel/AfterTrainingCarousel";

import { AFTER_DM_TRAININGS_CONSTANTS } from "../../../shared/constants/afterTrainings.constants";
import "./DataManagementTraining.scss";

const { Text } = Typography;

const DataManagementTraining = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="dataManagementTraining">
      <div className="ourTrainingPrograms">
        <div className="ourTrainingProgramsBackground" />
        <div className="ourTrainingProgramsBlurBackground" />
        <div
          id="ourTrainingProgramsContent"
          className="ourTrainingProgramsContent"
        >
          <div className="ourTrainingProgramsContentContainer">
            <Text className="ourTrainingProgramsContentSubtitle">
              “Our in-depth training programs are designed to provide
              comprehensive knowledge and practice for all the people who want
              to start a successful career in the data field or for those who
              want to advance their skills through an interactive and practical
              learning experience” - Head of Department.
            </Text>
          </div>
        </div>
      </div>
      <div className="afterSASTrainingSection">
        <Text className="afterSASTrainingSectionSubtitle">
          The purpose of this training is to provide hands-on experience with
          data management by providing all the necessary tools and knowledge for
          further practice. By completing STATECS data management training, the
          participants will have a full understanding of data management systems
          and will be able to apply their data analysis and management skills to
          transition into a more data-oriented organization.
        </Text>
        <Text className="afterSASTrainingSectionCardsTitle">
          What will you get?
        </Text>
        <div className="afterTrainingsCards">
          {AFTER_DM_TRAININGS_CONSTANTS?.map(({ icon, content }, index) => {
            return (
              <AfterTrainingsCard icon={icon} content={content} key={index} />
            );
          })}
        </div>
        <AfterTrainingCarousel />
      </div>
      <div className="whyChooseStatecsTrainings">
        <div className="whyChooseStatecsTrainingsBackground" />
        <div className="whyChooseStatecsTrainingsContent">
          <div className="whyChooseStatecsTrainingsContentContainer">
            <Text className="whyChooseStatecsTrainingsContentSubtitle">
              Don’t miss the opportunity to take the next step in your career
              and improve your skills for the successful career of yours. If you
              are interested in our training programs and having the chance to
              work at STATECS then apply now below!
            </Text>
            <NavLink
              to="/trainings/apply-data-management-trainings"
              className="trainingApplyButton"
              onClick={scrollToTop}
            >
              Apply
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataManagementTraining;
