export const getVacanciesSelector = (state) => state.vacancies;
export const getBlogSelector = (state) => state.blog;
export const getBlogPageSelector = (state) => state.blogPage.data;
export const getBlogByIdSelector = (state) => state.blogById;
export const blogRelatedPostsSelector = (state) => state.blogRelatedPosts;
export const contactUsSelector = (state) => state.contactUs;
export const sasTrainingsSelector = (state) => state.sasTrainings;
export const dmTrainingsSelector = (state) => state.dmTrainings;
export const contactForTrainingsSelector = (state) => state.contactForTrainings;
export const applyForJobSelector = (state) => state.applyJob;
export const newsletterSubscriptionSelector = (state) =>
  state.newsletterSubscription;
