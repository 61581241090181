import { getBlogPageActionEnum } from "../actionType";

export const getBlogPageReducer = function (
  state = { data: [], error: null, loading: false },
  action
) {
  switch (action.type) {
    case getBlogPageActionEnum.IS_LOADING_BLOG_PAGE:
      return { ...state, loading: true };
    case getBlogPageActionEnum.GET_BLOG_PAGE_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case getBlogPageActionEnum.GET_BLOG_PAGE_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};
