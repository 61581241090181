import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "antd";

import arrowLeftIcon from "../../../../assets/images/arrow-left.svg";
import "./OurMission.scss";

const { Text } = Typography;

const OurMission = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="ourMissionSection">
      <div className="ourMissionContainer">
        <div className="ourMissionContent">
          <h2 className="ourMissionTitle">Our Mission</h2>
          <Text className="ourMissionSubtitle">
            We are dedicated to changing the healthcare and medical system for
            the better by providing next-generation solutions to worldwide
            clients. Putting purpose into practice, STATECS programmers combine
            technology and data to create innovative solutions for a healthier
            future.
          </Text>
          <div className="seeMoreButtonContainer">
            <Link
              to="/about-us"
              className="seeMoreButton"
              onClick={scrollToTop}
            >
              <Text className="seeMoreButtonContent">See more</Text>
              <img src={arrowLeftIcon} alt="arrow-left-icon" className="icon" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
