import React from "react";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet";

import { Typography } from "antd";
import TrainingCalendar from "../../shared/components/TrainingCalendar/TrainingCalendar";
import TrainingApplyForm from "./TrainingApplyForm/TrainingApplyForm";

import "./ApplySASTrainingPage.scss";

const { Text } = Typography;

const ApplySASTrainingPage = () => {
  return (
    <div className="applySASTrainingPage">
      <Helmet>
        <meta name="title" content="SAS Training Programs | STATECS" />
        <meta
          name="description"
          content="At STATECS  we provide an in-depth intensive SAS training program that is  delivered by experienced and highly qualified specialists."
        />
        <title>SAS Training Programs | STATECS</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="applySASTrainingPageHeader">
        <div className="applySASTrainingPageHeaderBackground"></div>
        <div className="applySASTrainingPageHeaderContainer">
          <Text className="applySASTrainingPageHeaderTitle">SAS Training</Text>
          <Text className="applySASTrainingPageHeaderSubtitle">
            In today's technologically directed world, data is everywhere, even
            if we do not always realize it! It is particularly fundamental for
            statistical analysis. At STATECS, we not only provide professional
            SAS (Statistical Analysis System) service but also an in-depth SAS
            training program, delivered by experienced and highly qualified
            specialists.
          </Text>
          <TrainingCalendar />
          <Link
            to="trainingApplyForm"
            smooth="true"
            offset={-90}
            duration={800}
            className="applySASTrainingApplyButton"
          >
            Apply
          </Link>
        </div>
      </div>
      <TrainingApplyForm />
    </div>
  );
};

export default ApplySASTrainingPage;
