import { getBlogRelatedPostsActionEnum } from "../actionType";
import { GetBlogRelatedPosts } from "../service/service";

export const getBlogRelatedPostsAction = (id) => async (dispatch) => {
  dispatch({
    type: getBlogRelatedPostsActionEnum.IS_LOADING_BLOG_RELATED_POSTS,
  });
  try {
    const data = await GetBlogRelatedPosts(id);

    dispatch({
      type: getBlogRelatedPostsActionEnum.GET_BLOG_RELATED_POSTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: getBlogRelatedPostsActionEnum.GET_BLOG_RELATED_POSTS_ERROR,
      error: error,
    });
  }
};
