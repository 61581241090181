import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getBlogRelatedPostsAction } from "../../../redux/action";
import { blogRelatedPostsSelector } from "../../../redux/selector/selector";

import { Row, Carousel } from "antd";
import BlogPageItemCard from "../BlogPageItemCard/BlogPageItemCard";

import "./BlogRelatedPostsCarouselMobile.scss";

const BlogRelatedPostsCarouselMobile = () => {
  const dispatch = useDispatch();
  const blogRelatedPosts = useSelector(blogRelatedPostsSelector);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getBlogRelatedPostsAction(id));
  }, [dispatch, id]);

  return (
    <div className="relatedPostsMobileCarousel">
      <Row className="carousel" id="carousel">
        <Carousel autoplay>
          {blogRelatedPosts?.data?.map(({ image_url, title, text, id }) => {
            return (
              <BlogPageItemCard
                img={image_url}
                title={title}
                subtitle={text}
                key={id}
                id={id}
              />
            );
          })}
        </Carousel>
      </Row>
    </div>
  );
};

export default BlogRelatedPostsCarouselMobile;
