import React from "react";

import { Typography } from "antd";

import "./ClinicalTrialPhasesContent.scss";

const { Text } = Typography;

const ClinicalTrialPhasesContent = () => {
  return (
    <div className="clinical-trial-phases">
      <Text className="text">
        There are 3 main phases of clinical trials during which a drug/medical
        device/a new treatment method is tested to see if it is effective enough
        for public use. These phases are monitored by FDA and will need its
        approval in the final stage of the new product to go out to the market.
      </Text>
      <Text className="title">Phase 1</Text>
      <Text className="text">
        The purpose of Phase 1 is to ensure that the treatment is safe and can
        be used in humans. During this phase the following points are covered:
      </Text>
      <Text className="subtext">The highest dose of the drug that is safe</Text>
      <Text className="subtext">Possible side effects at different doses</Text>
      <Text className="subtext">The way the body gets rid of the drug</Text>
      <Text className="subtext">Any positive effects of the treatment</Text>
      <Text className="text">
        Only a few people are recruited to test the treatment in this phase. At
        the end of Phase 1, the results are collected and analyzed then
        submitted to the FDA. If they are approved, you can proceed to Phase 2.
      </Text>
      <Text className="title">Phase 2</Text>
      <Text className="text">
        During Phase 2, the effectiveness of the drug is determined as well as
        the right dosage to be used by patients. At this stage, a larger number
        of volunteers are involved in the treatment testing process.
      </Text>
      <Text className="text">
        Different treatment groups are formed and while some get different doses
        of the new drug, others (included in a control group) receive either the
        standard type of treatment (if there is already one) or a placebo.
      </Text>
      <Text className="text">
        Eventually, the results are compared and if the new treatment happens to
        be ineffective or gives the same results as the existing one, or worse,
        if it accelerates the disease development, the FDA will not permit Phase
        3.
      </Text>
      <Text className="title">Phase 3</Text>
      <Text className="text">
        Phase 3 clinical trials usually include several hundred volunteers and
        can be carried out across the whole country or even worldwide. Again, a
        control group is formed that receives a placebo while other treatment
        groups take the actual drug. The difference between Phase 2 and Phase 3
        is that the latter involves a wider range of people at various stages of
        the disease.
      </Text>
      <Text className="text">
        When the results are ready, the health of the patients from different
        treatment groups is compared to that of the control group patients. If
        the results show no positive effects or even worse if they have hurt the
        patient's health, the FDA might not grant its permission to apply for a
        New Drug Application (NDA). The latter is a submission to the FDA for a
        final review and if approved, the drug will go on sale in the market.
      </Text>
      <Text className="title">Phase 4</Text>
      <Text className="text">
        This phase is optional and not always carried out. After the FDA
        approves the new product, it enters the phase of Clinical
        Trial/Post-Market Surveillance/Report Adverse Events phase. While on the
        market, the FDA keeps an eye on the treatment to monitor any possible
        adverse effects it can have.
      </Text>
      <Text className="text">
        There can also be Phase 0 trials before Phase 1 starts. These are
        usually optional, involving very few people (up to 15) and testing low
        doses of the drug. Phase 0 is not common in drug testing and when
        implemented, is only aimed to see the way the drug acts in the human
        body. In this phase the patients won’t feel any positive changes or
        effects of the drug. It will also be quite safe for the volunteers.
      </Text>
      <Text className="text">
        At STATECS, our experts will support you across all of these phases by
        providing you with the latest technologies and useful advice throughout
        the overall process. We have the right specialists with the
        corresponding skills for every phase. They will help you determine your
        goals more precisely while ensuring data safety across all stages. Due
        to our client-oriented approach and trust-based collaboration with our
        customers, we can deliver the best results at affordable prices,
        maintaining quality and ethical standards.
      </Text>
    </div>
  );
};

export default ClinicalTrialPhasesContent;
