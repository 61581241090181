import React from "react";
import { Link } from "react-scroll";

import { Typography } from "antd";

import doctorImage from "../../../../assets/images/after-trainings-person.png";
import "./AfterTrainings.scss";

const { Text } = Typography;

const AfterTrainings = () => {
  return (
    <div className="afterTrainings">
      <div className="afterTrainingsBackground" />

      <div className="afterTrainingsHeader">
        <img
          src={doctorImage}
          alt="after-trainings-person"
          className="afterTrainingsHeaderImage"
        />
        <div className="afterTrainingsHeaderContent">
          <Text className="afterTrainingsHeaderTitle">
            After this thorough training,
          </Text>
          <Text className="afterTrainingsHeaderSubtitle">
            After this thorough trainings, you are guaranteed to master SAS and
            be able to manipulate large amounts of data to solve daily problems
            and acquire valuable insights. Besides that, all the successful
            participants who show great performance will have a chance to go
            through 1-3 month internship at STATECS and then get hired at the
            company.
          </Text>
          <Link
            Link
            to="trainingsSection"
            smooth="true"
            offset={-90}
            duration={800}
            className="applyForTraining"
          >
            Apply
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AfterTrainings;
