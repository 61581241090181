import { postNewsletterSubscriptionActionEnum } from "../actionType";

export const newsletterSubscriptionReducer = function (
  state = { data: [], error: null, loading: false },
  action
) {
  switch (action.type) {
    case postNewsletterSubscriptionActionEnum.IS_LOADING_NEWSLETTER_SUBSCRIPTION:
      return { ...state, loading: true };
    case postNewsletterSubscriptionActionEnum.POST_NEWSLETTER_SUBSCRIPTION_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case postNewsletterSubscriptionActionEnum.POST_NEWSLETTER_SUBSCRIPTION_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};
