import React from "react";

import { Typography } from "antd";
import { Collapse } from "antd";

import { DownOutlined } from "@ant-design/icons";
import "./Collapse.scss";

const { Text } = Typography;
const { Panel } = Collapse;

const CollapseComponent = ({ icon, title, subtitle, panelKey, content }) => {
  return (
    <div className="collapse-container">
      <Collapse
        className="collapse"
        expandIconPosition="end"
        bordered={false}
        accordion={true}
        expandIcon={({ isActive }) => (
          <DownOutlined
            rotate={isActive ? 180 : 0}
            style={{
              fontSize: "32px",
              fontWeight: "700",
              color: "white",
              marginRight: "50px",
            }}
            className="arrowIcon"
          />
        )}
      >
        <Panel
          header={
            <div className="panel-header">
              <div className="icon-container">
                <img src={icon} alt="panel-icon" className="icon" />
              </div>
              <Text className="panel-header-title">{title}</Text>
              <Text className="panel-header-subtitle">{subtitle}</Text>
              <DownOutlined
                style={{
                  fontSize: "32px",
                  fontWeight: "700",
                  color: "white",
                  marginTop: "16px",
                }}
                className="arrowIcon"
              />
            </div>
          }
          key={`${panelKey}`}
          className="panel"
        >
          {content}
        </Panel>
      </Collapse>
    </div>
  );
};

export default CollapseComponent;
