import { getBlogActionEnum } from "../actionType";
import { GetBlog } from "../service/service";

export const getBlogAction = (page, itemPerPage) => async (dispatch) => {
  dispatch({ type: getBlogActionEnum.IS_LOADING_BLOG });
  try {
    const data = await GetBlog(page, itemPerPage);

    dispatch({
      type: getBlogActionEnum.GET_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: getBlogActionEnum.GET_BLOG_ERROR,
      error: error,
    });
  }
};
