import React from "react";

import BlogSinglePageContent from "./BlogSinglePageContent/BlogSinglePageContent";
import BlogRelatedPostsCarousel from "./BlogRelatedPostsCarousel/BlogRelatedPostsCarousel";
import BlogRelatedPostsCarouselMobile from "./BlogRelatedPostsCarouselMobile/BlogRelatedPostsCarouselMobile";

import "./BlogSinglePage.scss";

const BlogSinglePage = () => {
  return (
    <div className="blogSinglePage">
      <div className="blogSinglePageHeader" />
      <BlogSinglePageContent />
      <BlogRelatedPostsCarousel />
      <BlogRelatedPostsCarouselMobile />
    </div>
  );
};

export default BlogSinglePage;
