import React from "react";
import { Link } from "react-router-dom";

import { Typography, Collapse } from "antd";

import {
  FOOTER_SERVICES_ITEMS,
  FOOTER_COMPANY_ITEMS,
  FOOTER_LEGAL_ITEMS,
} from "../../shared/constants/footerMobileItems.constants";

import phoneIcon from "../../assets/images/footer-phone-icon.png";
import mailIcon from "../../assets/images/footer-mail-icon.png";
import locationIcon from "../../assets/images/footer-location-icon.png";
import facebookIcon from "../../assets/images/facebook-icon.png";
import linkedinIcon from "../../assets/images/linkedin-icon.png";
import statecsLogo from "../../assets/images/statecs-logo-colorful.png";
import { DownOutlined } from "@ant-design/icons";

import "./AppFooter.scss";

const { Text } = Typography;
const { Panel } = Collapse;

const AppFooter = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="appFooter">
      <div className="appFooterContainer">
        <div className="footerNavigation">
          <div className="footerNavigationSections">
            <Link to="/" className="title">
              Company
            </Link>
            <Link to="/expertise" className="item" onClick={scrollToTop}>
              Expertise
            </Link>
            <Link to="/about-us" className="item" onClick={scrollToTop}>
              About us
            </Link>
            <Link to="/blog" className="item" onClick={scrollToTop}>
              Blog
            </Link>
            <Link to="/careers" className="item" onClick={scrollToTop}>
              Careers
            </Link>
          </div>
          <div className="footerCollapse">
            <Collapse
              className="collapse"
              expandIconPosition="end"
              bordered={false}
              accordion={true}
              expandIcon={({ isActive }) => (
                <DownOutlined
                  rotate={isActive ? 180 : 0}
                  className="arrowIcon"
                />
              )}
            >
              <Panel
                header={
                  <Text to={"/"} className="link">
                    <Text className="footerItem">Company</Text>
                  </Text>
                }
                key={1}
                className="panel"
              >
                {FOOTER_COMPANY_ITEMS?.map(({ href, content }) => {
                  return (
                    <Link
                      to={href}
                      className="link"
                      key={href}
                      onClick={scrollToTop}
                    >
                      <Text className="footerItem">{content}</Text>
                    </Link>
                  );
                })}
              </Panel>
            </Collapse>
          </div>

          <div className="footerNavigationSections">
            <Link to="/services" className="title" onClick={scrollToTop}>
              Services
            </Link>
            <Link
              to="/services/statistical-programming"
              className="item"
              onClick={scrollToTop}
            >
              Statistical Programming
            </Link>
            <Link
              to="/services/clinical-data-management"
              className="item"
              onClick={scrollToTop}
            >
              Clinical Data Management
            </Link>
            <Link
              to="/services/medical-writing"
              className="item"
              onClick={scrollToTop}
            >
              Medical Writing
            </Link>
            <Link
              to="/services/regulatory-affairs"
              className="item"
              onClick={scrollToTop}
            >
              Regulatory Affairs
            </Link>
          </div>
          <div className="footerCollapse">
            <Collapse
              className="collapse"
              expandIconPosition="end"
              bordered={false}
              accordion={true}
              expandIcon={({ isActive }) => (
                <DownOutlined
                  rotate={isActive ? 180 : 0}
                  className="arrowIcon"
                />
              )}
            >
              <Panel
                header={
                  <Text className="link">
                    <Text className="footerItem">Services</Text>
                  </Text>
                }
                key={1}
                className="panel"
              >
                {FOOTER_SERVICES_ITEMS?.map(({ href, content }) => {
                  return (
                    <Link
                      to={href}
                      className="link"
                      key={href}
                      onClick={scrollToTop}
                    >
                      <Text className="footerItem">{content}</Text>
                    </Link>
                  );
                })}
              </Panel>
            </Collapse>
          </div>
          <div className="footerNavigationSections">
            <Text className="title">Legal</Text>
            <Link to="/privacy-policy" className="item" onClick={scrollToTop}>
              Privacy Policy
            </Link>
            <Link
              to="/term-and-conditions"
              className="item"
              onClick={scrollToTop}
            >
              Terms of Service
            </Link>
          </div>
          <div className="footerCollapse">
            <Collapse
              className="collapse"
              expandIconPosition="end"
              bordered={false}
              accordion={true}
              expandIcon={({ isActive }) => (
                <DownOutlined
                  rotate={isActive ? 180 : 0}
                  className="arrowIcon"
                />
              )}
            >
              <Panel
                header={
                  <Text className="link">
                    <Text className="footerItem">Legal</Text>
                  </Text>
                }
                key={1}
                className="panel"
              >
                {FOOTER_LEGAL_ITEMS?.map(({ content, path }) => {
                  return (
                    <Link
                      to={path}
                      className="link"
                      key={content}
                      onClick={scrollToTop}
                    >
                      <Text className="footerItem">{content}</Text>
                    </Link>
                  );
                })}
              </Panel>
            </Collapse>
          </div>
          <div className="footerNavigationSectionsContact">
            <Text className="title">Contact</Text>
            <div className="prefixItem">
              <img src={phoneIcon} alt="footer-phone-icon" />
              <a className="contactItem" href="tel:+37411666101">
                +374 11 666101
              </a>
            </div>
            <div className="prefixItem">
              <img src={mailIcon} alt="footer-mail-icon" />
              <Text
                className="contactItem"
                onClick={() => {
                  window.open("mailto:info@statecs.com");
                }}
              >
                info@statecs.com
              </Text>
            </div>
            <div className="prefixItem">
              <img src={locationIcon} alt="footer-location-icon" />
              <Text className="contactItem">
                Holy Abgar King Street, 42/8, Yerevan, Armenia
              </Text>
            </div>
          </div>
        </div>
        <div className="statecs">
          <Link to="/" onClick={scrollToTop}>
            <img src={statecsLogo} alt="statecs-logo" className="logo" />
          </Link>

          <Text className="rights">© 2021 STATECS | All Rights Reserved</Text>
          <div className="socialMedia">
            <Text className="followUs">Follow us</Text>
            <div className="socialMediaIcons">
              <a target="_blanck" href="https://www.facebook.com/statecsllc">
                <img
                  src={facebookIcon}
                  alt="facebook-icon"
                  className="socialMediaIcoн"
                />
              </a>

              <a
                target="_blanck"
                href="https://www.linkedin.com/company/statecs-llc/"
              >
                <img
                  src={linkedinIcon}
                  alt="linkedin-icon"
                  className="socialMediaIcoн"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
